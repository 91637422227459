import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import Countdown from 'react-countdown';

import styles from './gifts.module.css';

const Gifts = ({scroll, renderer, date}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    return (
        <div className={styles.gifts}>
            <div className="container">
                <div className={styles.titles}>
                    <div>
                    <div className={styles.gifts_pretitle}>ПРИ ПОКУПКЕ В&nbsp;ТЕЧЕНИЕ 15&nbsp;МИНУТ</div>
                        <div className={styles.gifts_title}><span>Дарим 8&nbsp;ценных гайдов</span><br/> для улучшения результата</div>
                        <div className={styles.gifts_subtitle}>В&nbsp;них&nbsp;&mdash; ценные советы по&nbsp;питанию, тренировкам<br/> и&nbsp;удержанию формы.</div>
                    </div>

                    <div className={styles.navigation}>
                        <div ref={navigationPrevRef} className={styles.slider_btn_prev}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                        </div>
                        <div ref={navigationNextRef} className={styles.slider_btn_next}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/arrow_slide.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
                <div className={styles.block}>
                        <Swiper
                            speed={800}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            slidesPerView={'auto'}
                            spaceBetween={20}
                            loop
                            onSwiper={(swiper) => {
                                setTimeout(() => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current
                                swiper.params.navigation.nextEl = navigationNextRef.current
                                
                                swiper.navigation.destroy()
                                swiper.navigation.init()
                                swiper.navigation.update()
                                })
                            }}
                            modules={[Navigation]}
                            className={`swiper_gt ${styles.swiper}`}>   
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/protokol-pit.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Протоколы питания: плюсы, минусы и&nbsp;основные принципы&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/sport-eat.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Спортивное питание и&nbsp;добавки&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/taning-in.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Тренажёрный зал в&nbsp;вашем кармане&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/buisns.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>Чек-лист</span>
                                        <p>&laquo;Секреты фитнес-режима для занятых женщин&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/back-to-tren.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Как восстановиться после перерыва в&nbsp;тренировках&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/prop-eat.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Правила сытного диетического ужина&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/best-time.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>Руководство</span>
                                        <p>&laquo;Как выбрать подходящее время для тренировки&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/vitam.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Витамины как источник энергии для тренировок&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                            







                            {/* <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g1.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Витамины как источник энергии для тренировок&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                           
                            
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g2.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Спортивное питание и&nbsp;добавки&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g3.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Протоколы питания: плюсы, минусы и&nbsp;основные принципы&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g4.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>путеводитель</span>
                                        <p>&laquo;Оборудование и&nbsp;инвентарь в&nbsp;тренажёрном зале&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g5.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>Как избежать травм: что нужно знать о&nbsp;правильной технике&quot;</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            
                            
                            <SwiperSlide className={styles.slide}>
                                <div className={styles.slide_block}>
                                    <div className={styles.slide_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/gymfeb/g8.png" alt="" />
                                    </div>
                                    <div className={styles.slide_info}>
                                        <span>гайд</span>
                                        <p>&laquo;Правила хорошего тона в&nbsp;тренажёрном зале&raquo;</p>
                                    </div>
                                </div>
                            </SwiperSlide> */}
                        </Swiper>
                </div>

                <div className={styles.btn} onClick={scroll}>
                        <p>Забрать подарки</p>
                    </div>
                <div className={styles.promo_countdown}>
                    <p>Успейте забрать набор программ<br/> и&nbsp;получите все подарки:</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + 900000} renderer={renderer} />
                    </div>
                </div>
            </div>
                
    )
}

export default Gifts;
import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_block}>
                    <div className={styles.author_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/author2.png" alt="" />
                    </div>
                    <div className={styles.author_info}>
                        <div className={styles.author_title}>Катя Усманова 100% знает<br/> <span>формулу идеальной фигуры</span></div>
                        {/* <div className={styles.author_subtitle}>С 2015&nbsp;года создаёт результативные тренировки для идеальных ягодиц, плоского живота и&nbsp;стройности без жёстких диет.<br/> <span>Уже более 310&nbsp;000&nbsp;участниц тренируются с&nbsp;Катей, ведь она:</span></div> */}
                        <ul className={styles.author_list}>
                            <li><b>Создатель GymTeam</b>&nbsp;— платформы с&nbsp;безлимитными тренировками экстра-класса от&nbsp;практикующих экспертов</li>
                            <li><b>Чемпионка России</b> и&nbsp;мира по&nbsp;жиму лёжа</li>
                            <li><b>Профессиональный фитнес-тренер</b> с&nbsp;опытом более&nbsp;15&nbsp;лет</li>
                            <li>Мама 2-х&nbsp;детей. Всего за&nbsp;100&nbsp;дней после первых родов <b>похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю форму</b></li>
                            <li><b>Автор первых в&nbsp;России</b> масштабных марафонов стройности</li>
                            <li><b>Вице-чемпионка мира</b> и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                        </ul>
                    </div>
                </div>
                
                <div className={styles.mob}>
                    {/* <div className={styles.author_imgmob}><img src="https://gymteam.kinescopecdn.net/img/l/2024/springawake/author_mob.jpg" alt="" /></div> */}
                        <ul className={styles.author_list}>
                            <li><b>Создатель GymTeam</b>&nbsp;— платформы с&nbsp;безлимитными тренировками экстра-класса от&nbsp;практикующих экспертов</li>
                            <li><b>Чемпионка России</b> и&nbsp;мира по&nbsp;жиму лёжа</li>
                            <li><b>Профессиональный фитнес-тренер</b> с&nbsp;опытом более&nbsp;15&nbsp;лет</li>
                            <li>Мама 2-х&nbsp;детей. Всего за&nbsp;100&nbsp;дней после первых родов <b>похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю форму</b></li>
                            <li><b>Автор первых в&nbsp;России</b> масштабных марафонов стройности</li>
                            <li><b>Вице-чемпионка мира</b> и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                        </ul>
                </div>
                
                <div className={styles.author_imgs}>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_1.jpg" alt="" />
                        <p>2015</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_2.jpg" alt="" />
                        <p>2016</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_3.jpg" alt="" />
                        <p>2017</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_4.jpg" alt="" />
                        <p>2018</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_5.jpg" alt="" />
                        <p>2019</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_6.jpg" alt="" />
                        <p>2020</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_7.jpg" alt="" />
                        <p>2021</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_8.jpg" alt="" />
                        <p>2022</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_9.jpg" alt="" />
                        <p>2023</p>
                    </div>
                    <div className={styles.author_imgs_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/gymfest/author/author_10.png" alt="" />
                        <p>2024</p>
                    </div>
                </div>

                <div className={styles.author_preims}>
                    <div className={styles.author_item}>
                        <div className={styles.author_item_name}>&gt;8&nbsp;ЛЕТ</div>
                        <div className={styles.author_item_text}>развиваем индустрию<br/> онлайн-фитнеса</div>
                    </div>
                    <div className={styles.author_item}>
                        <div className={styles.author_item_name}>&gt;4&nbsp;000+</div>
                        <div className={styles.author_item_text}>учеников со&nbsp;всего мира<br/> тренируются с&nbsp;Катей<br/> каждый день</div>
                    </div>
                    <div className={styles.author_item}>
                        <div className={styles.author_item_name}>&gt;310&nbsp;000</div>
                        <div className={styles.author_item_text}>историй успеха,<br/> которые начались с&nbsp;мечты<br/> о&nbsp;шикарном теле</div>
                    </div>
                    <div className={styles.author_item}>
                        <div className={styles.author_item_name}>9 из&nbsp;10</div>
                        <div className={styles.author_item_text}>участниц получили<br/> желаемый результат</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;
import Countdown from 'react-countdown';
import styles from './gifts.module.css';

const Gifts = ({scroll, date, renderer}) => {

    return (
        <div className={styles.gifts}>
            <div className="container">
                <div className={styles.block_pretitle}>ПРИ ПОКУПКЕ В ТЕЧЕНИЕ<br className={styles.br_mob}/> 15 МИНУТ ВЫ ПОЛУЧАЕТЕ</div>
                <div className={styles.gifts_title}>6&nbsp;подробных гайдов в&nbsp;подарок<br/> для новичков и&nbsp;опытных на&nbsp;<span className={styles.old_price}>4&nbsp;000&nbsp;₽</span><br/> <span>бесплатно</span></div>
                <div className={styles.gifts_subtitle}>Они помогут улучшить результаты и&nbsp;сделать тренировки частью вашей жизни</div>
                <div className={styles.gifts_block}>
                    <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif1.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                            <span>500 ₽</span>
                        </div>
                    </div>
                    <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif2.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                            <span>500 ₽</span>
                        </div>
                    </div>
                    <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif3.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                            {/* <p><b>Гайд:</b> Спортивные аскезы</p> */}
                            <span>500 ₽</span>
                        </div>
                    </div>
                    <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif4.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                            {/* <p><b>Советы:</b> 8&nbsp;вещей, которые нужно знать новичку перед началом тренировок</p> */}
                            <span>500 ₽</span>
                        </div>
                    </div>
                    {/* <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif5.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                            <p><b>Сборник:</b> 17&nbsp;советов для эффективной растяжки</p>
                            <span>500 ₽</span>
                        </div>
                    </div> */}
                    <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif6.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                            {/* <p><b>Руководство:</b> Как избавиться от&nbsp;пищевой зависимости</p> */}
                            <span>500 ₽</span>
                        </div>
                    </div>
                    <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif7.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                         
                            <span>500 ₽</span>
                        </div>
                    </div>
                    {/* <div className={styles.gifts_item}>
                        <div className={styles.gifts_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/gif8.jpg" alt="" />
                        </div>
                        <div className={styles.gifts_item_info}>
                          
                            <span>500 ₽</span>
                        </div>
                    </div>    */}
                </div>
                <div className={styles.promo_btns}>
                        <div className={styles.promo_btn} onClick={scroll}>ЗАБРАТЬ ВСЕ ПОДАРКИ</div>
                        <div className={styles.promo_countdown}>
                            <p>Бонусы сгорят через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + 900000} renderer={renderer} />
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Gifts;
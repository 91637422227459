import styles from './second.module.css';

const Second2 = () => {
    return (
        <div className={styles.second_block}>
            <div className={styles.second}>
                <div className={styles.line}>
                    <div className={styles.line_text}>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                        <p>ЧАТ С КУРАТОРОМ</p>
                        <div className={styles.dot}></div>
                    </div>
                </div>
            </div>
            <div className={styles.second2}>
                <div className={styles.line2}>
                    <div className={styles.line_text}>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                        <p>старт сразу после покупки</p>
                        <div className={styles.dot}></div>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Second2;
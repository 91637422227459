import styles from './second.module.css';

const Second = () => {
    return (
        <div className={styles.second}>
            {/* <div className={styles.line}>
                <div className={styles.line_text}>
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                    <p>выгода 60%</p>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/marker.svg" alt="" />
                </div>
            </div> */}
        
            <div className="container">
                {/* <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>СТАРТ<br/> ТРЕНИРОВОК</div>
                        <div className={styles.item_t2}>сразу после оплаты,<br/> чтобы начать действовать уже сейчас</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>КОМФОРТНЫЙ<br/> ДОСТУП</div>
                        <div className={styles.item_t2}>даём доступ на&nbsp;2&nbsp;месяца к&nbsp;марафонам,<br/> на&nbsp;5&nbsp;месяцев&nbsp;&mdash; к&nbsp;курсам</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_t1}>5&nbsp;ПОЛЕЗНЫХ<br/> ГАЙДОВ</div>
                        <div className={styles.item_t2}>Дарим 5&nbsp;гайдов на&nbsp;актуальные<br/> темы при покупке</div>
                    </div>
                </div> */}

                {/* <div className={styles.b2}>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/last.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Рекомендации по&nbsp;прохождению программ</p>
                            <p>сразу после покупки</p>
                        </div>
                    </div>
                    
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_5.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Можно выбрать свою<br/> дату старта</p>
                            <p>Будет 2 потока участниц:<br/> <b> 16 декабря или 13 января</b></p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_11.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Дарим программу питания</p>
                            <p>при покупке от&nbsp;2&nbsp;любых<br/> программ</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/w_2_7.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Разыгрываем поездку в&nbsp;Дубай и&nbsp;встречу с&nbsp;Катей</p>
                            <p>среди всех купивших<br/> на&nbsp;Чёрной пятнице</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/9/dyson_2.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Разыгрываем стайлер&nbsp;Dyson<br/> и&nbsp;другие ценные призы</p>
                            <p>Разыгрываем стайлер&nbsp;Dyson<br/> и&nbsp;другие ценные призы</p>
                        </div>
                    </div>
                    <div className={styles.b2_item}>
                        <div className={styles.b2_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/9/gude.png" alt="" />
                        </div>
                        <div className={styles.b2_item_text}>
                            <p>Дарим 10&nbsp;полезных гайдов</p>
                            <p>чтобы помочь вам разобраться со&nbsp;всеми нюансами тренировок и&nbsp;питания</p>
                        </div>
                    </div>  
                </div> */}

                <div className={styles.block2}>
                    <div className={styles.block2_title}>Выбирайте программы под свои цели<br/> <span>и&nbsp;создайте свою лучшую форму</span> к&nbsp;лету</div>
                    <div className={styles.block2_subtitle}>10&nbsp;программ с&nbsp;домашними тренировками на&nbsp;выбор. Похудеете, сделаете тело стройнее, накачаете попу, избавитесь целлюлита и&nbsp;создадите плоский живот</div>
                </div>
            </div>
        
        </div>
        
    )
}

export default Second;
import styles from './programs.module.css';

const Programs = ({scroll}) => {
    return (
        <div className={`choose ${styles.programs}`}>
            <div className="container">
                
                <div className={styles.t1}>НАБОР программ<br/> <span>&laquo;ТЕЛО В&nbsp;ТОНУСЕ&raquo;</span></div>
                <div className={styles.st1}>Чтобы быстро и&nbsp;безопасно сбросить лишний вес,<br/> подтянуть ягодичные мышцы и&nbsp;пресс</div>
                <div className={styles.dts}>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/lks/pr1.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 3&nbsp;месяца</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/lks/pr2.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Старт&nbsp;&mdash; сразу после оплаты</div>
                    </div>
                </div>
            </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>6 490 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Без инвентаря</b></li>
                                        <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Как всё устроено <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже обладает некоторым опытом. <b>Программа поможет быстро похудеть</b> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn1" name="pn1" />
                                        <label htmlFor="pn1">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Похудеете минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянете руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшите самочувствие и&nbsp;повысите уровень энергии</li>
                                                <li>Разберетест в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаете фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>9 990 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p2" name="p2" />
                                    <label htmlFor="p2">Как всё устроено <p>Марафон для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn2" name="pn2" />
                                        <label htmlFor="pn2">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr3n.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>6 490 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Без инвентаря</b></li>
                                    <li><b>Длительность тренировок:</b> 15-30&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5" name="p5" />
                                    <label htmlFor="p5">Как всё устроено <p>Марафон направлен на&nbsp;глубокую проработку мышц пресса <b>для создания красивого рельефа на&nbsp;животике</b>.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn5" name="pn5" />
                                        <label htmlFor="pn5">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Уменьшите объемы талии и&nbsp;живота</li>
                                                <li>Повысите мышечный тонус всего тела и&nbsp;подтянете мышцы пресса</li>
                                                <li>Снизите процент жира в&nbsp;области живота</li>
                                                <li>Улучшите осанку и&nbsp;усилите поддержку спины</li>
                                                <li>Повысите общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретете знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div className={styles.btns}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Забрать набор</p>
                    </div>
                    <div className={styles.btns_prices}>
                        <div className={styles.btns_prices_top}>
                            <p>за&nbsp;2&nbsp;990&nbsp;₽</p>
                            <span>19&nbsp;470&nbsp;₽</span>
                        </div>
                        <div className={styles.btns_prices_bottom}>Выгода 85%!</div>
                    </div>
                </div>



            {/* <div className={styles.bg}>
                <div className="container">
                
                <div className={styles.t1}>НАБОР программ<br/> <span>&laquo;ПРОДВИНУТЫЙ&raquo;</span></div>
                <div className={styles.dts}>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr1.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 9&nbsp;месяцев</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr2.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Старт&nbsp;&mdash; 16 октября</div>
                    </div>
                </div>
            </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Без инвентаря</b></li>
                                        <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1z" name="p1z" />
                                        <label htmlFor="p1z">Как всё устроено <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже обладает некоторым опытом. <b>Программа поможет быстро похудеть</b> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn1z" name="pn1z" />
                                        <label htmlFor="pn1z">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Похудеешь минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянешь руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь уровень энергии</li>
                                                <li>Разберешься в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаешь фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p2z" name="p2z" />
                                    <label htmlFor="p2z">Как всё устроено <p>Марафон для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn2z" name="pn2z" />
                                        <label htmlFor="pn2z">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавишься от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr3n.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Без инвентаря</b></li>
                                    <li><b>Длительность тренировок:</b> 15-30&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5z" name="p5z" />
                                    <label htmlFor="p5z">Как всё устроено <p>Марафон направлен на&nbsp;глубокую проработку мышц пресса <b>для создания красивого рельефа на&nbsp;животике</b>.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn5z" name="pn5z" />
                                        <label htmlFor="pn5z">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Уменьшишь объемы талии и&nbsp;живота</li>
                                                <li>Повысишь мышечный тонус всего тела и&nbsp;подтянешь мышцы пресса</li>
                                                <li>Снизишь процент жира в&nbsp;области живота</li>
                                                <li>Улучшишь осанку и&nbsp;усилишь поддержку спины</li>
                                                <li>Повысишь общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретешь знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr4.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>7 500 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                    <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p6z" name="p6z" />
                                    <label htmlFor="p6z">Как всё устроено <p>Марафон для тех, кто хочет <b>избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи</b>, изменить качество тела. Внутри кроме тренировок техники самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6z" name="pn6z" />
                                        <label htmlFor="pn6z">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Избавишься от&nbsp;целлюлита и&nbsp;улучшишь текстуру кожи</li>
                                                <li>Повысишь тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнешь контуры тела и&nbsp;сформируешь более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоишь техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысишь уверенность в&nbsp;себе и&nbsp;улучшишь самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr5.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Придать телу рельеф</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>Жиросжигающий</span></div>
                                    <div className={styles.programs_img__info_price}>7 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Группы:</b> умеренный, интенсивный и силовой классы</li>
                                    <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p9z" name="p9z" />
                                    <label htmlFor="p9z">Как всё устроено <p>Марафон для тех, кто устал от&nbsp;неэффективных диет и&nbsp;экспериментов над своим телом и&nbsp;мечтаешь избавиться от&nbsp;лишнего жира в&nbsp;проблемных зонах.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn9z" name="pn9z" />
                                        <label htmlFor="pn9z">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Снизишь процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                <li>Станешь выносливее и&nbsp;улучшишь физическую форму благодаря интенсивным тренировкам.</li>
                                                <li>Усилишь мышечный корсет и&nbsp;осанку</li>
                                                <li>Уменьшишь объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                <li>Нормализуешь питание</li>
                                            </ul>
                                            <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr6.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><span>Универсальные тренировки на&nbsp;ягодицы</span></div>
                                    <div className={styles.programs_img__info_price}>4 790 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 10&nbsp;тренировок</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома</li>
                                    <li><b>Длительность тренировок:</b> 15-20&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p12z" name="p12z" />
                                    <label htmlFor="p12z">Как всё устроено <p>Марафон представляет собой конструктор из&nbsp;10&nbsp;тренировок, которые ты можешь комбинировать под физическую подготовку, самочувствие и&nbsp;даже настроение.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn12z" name="pn12z" />
                                        <label htmlFor="pn12z">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Укрепишь и&nbsp;подтянешь мышцы ягодиц</li>
                                                <li>Улучшишь форму и&nbsp;контуры ягодиц</li>
                                                <li>Повысишь выносливость и&nbsp;уровень энергии</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь энергию</li>
                                            </ul>
                                            <p>Марафон представляет собой уникальный конструктор из&nbsp;10&nbsp;тренировок на&nbsp;ягодицы, которые можно комбинировать в&nbsp;соответствии с&nbsp;твоими потребностями, физической подготовкой и&nbsp;настроением. Он&nbsp;разработан для того, чтобы обеспечить максимальную эффективность и&nbsp;удобство тренировок.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
                
                <div className={styles.btns}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Забрать набор</p>
                    </div>
                    <div className={styles.btns_prices}>
                        <div className={styles.btns_prices_top}>
                            <p>за&nbsp;3&nbsp;990&nbsp;₽</p>
                            <span>34&nbsp;000&nbsp;₽</span>
                        </div>
                        <div className={styles.btns_prices_bottom}>Выгода 88%!</div>
                    </div>
                </div>
                
            
            </div> */}
            

            {/* <div className="container">
                
                <div className={styles.t1}>НАБОР программ<br/> <span>&laquo;МАКСИМАЛЬНЫЙ&raquo;</span></div>
                <div className={styles.dts}>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr1.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 1&nbsp;год</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr2.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Старт&nbsp;&mdash; 16 октября</div>
                    </div>
                </div>
            </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <ul>
                                        <li><b>Длительность:</b> 21&nbsp;день</li>
                                        <li><b>Без инвентаря</b></li>
                                        <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                        <li><b>График тренировок:</b> ежедневно</li>
                                    </ul>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1x" name="p1x" />
                                        <label htmlFor="p1x">Как всё устроено <p>Марафон для&nbsp;новичков и&nbsp;тех, кто уже обладает некоторым опытом. <b>Программа поможет быстро похудеть</b> и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p></label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn1x" name="pn1x" />
                                        <label htmlFor="pn1x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Похудеешь минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянешь руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь уровень энергии</li>
                                                <li>Разберешься в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаешь фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p2x" name="p2x" />
                                    <label htmlFor="p2x">Как всё устроено <p>Марафон для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn2x" name="pn2x" />
                                        <label htmlFor="pn2x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавишься от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr3n.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>5 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Без инвентаря</b></li>
                                    <li><b>Длительность тренировок:</b> 15-30&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5x" name="p5x" />
                                    <label htmlFor="p5x">Как всё устроено <p>Марафон направлен на&nbsp;глубокую проработку мышц пресса <b>для создания красивого рельефа на&nbsp;животике</b>.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn5x" name="pn5x" />
                                        <label htmlFor="pn5x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Уменьшишь объемы талии и&nbsp;живота</li>
                                                <li>Повысишь мышечный тонус всего тела и&nbsp;подтянешь мышцы пресса</li>
                                                <li>Снизишь процент жира в&nbsp;области живота</li>
                                                <li>Улучшишь осанку и&nbsp;усилишь поддержку спины</li>
                                                <li>Повысишь общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретешь знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr4.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>7 500 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 30&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                    <li><b>Длительность тренировок:</b> 20-40&nbsp;минут</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p6x" name="p6x" />
                                    <label htmlFor="p6x">Как всё устроено <p>Марафон для тех, кто хочет <b>избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи</b>, изменить качество тела. Внутри кроме тренировок техники самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6x" name="pn6x" />
                                        <label htmlFor="pn6x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Избавишься от&nbsp;целлюлита и&nbsp;улучшишь текстуру кожи</li>
                                                <li>Повысишь тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнешь контуры тела и&nbsp;сформируешь более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоишь техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысишь уверенность в&nbsp;себе и&nbsp;улучшишь самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr5.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Придать телу рельеф</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>Жиросжигающий</span></div>
                                    <div className={styles.programs_img__info_price}>7 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 3&nbsp;месяца</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Группы:</b> умеренный, интенсивный и силовой классы</li>
                                    <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p9x" name="p9x" />
                                    <label htmlFor="p9x">Как всё устроено <p>Марафон для тех, кто устал от&nbsp;неэффективных диет и&nbsp;экспериментов над своим телом и&nbsp;мечтаешь избавиться от&nbsp;лишнего жира в&nbsp;проблемных зонах.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn9x" name="pn9x" />
                                        <label htmlFor="pn9x">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Снизишь процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                <li>Станешь выносливее и&nbsp;улучшишь физическую форму благодаря интенсивным тренировкам.</li>
                                                <li>Усилишь мышечный корсет и&nbsp;осанку</li>
                                                <li>Уменьшишь объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                <li>Нормализуешь питание</li>
                                            </ul>
                                            <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr6.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><span>Универсальные тренировки на&nbsp;ягодицы</span></div>
                                    <div className={styles.programs_img__info_price}>4 790 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 10&nbsp;тренировок</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома</li>
                                    <li><b>Длительность тренировок:</b> 15-20&nbsp;минут</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p12x" name="p12x" />
                                    <label htmlFor="p12x">Как всё устроено <p>Марафон представляет собой конструктор из&nbsp;10&nbsp;тренировок, которые ты можешь комбинировать под физическую подготовку, самочувствие и&nbsp;даже настроение.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn12x" name="pn12x" />
                                        <label htmlFor="pn12x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Укрепишь и&nbsp;подтянешь мышцы ягодиц</li>
                                                <li>Улучшишь форму и&nbsp;контуры ягодиц</li>
                                                <li>Повысишь выносливость и&nbsp;уровень энергии</li>
                                                <li>Улучшишь самочувствие и&nbsp;повысишь энергию</li>
                                            </ul>
                                            <p>Марафон представляет собой уникальный конструктор из&nbsp;10&nbsp;тренировок на&nbsp;ягодицы, которые можно комбинировать в&nbsp;соответствии с&nbsp;твоими потребностями, физической подготовкой и&nbsp;настроением. Он&nbsp;разработан для того, чтобы обеспечить максимальную эффективность и&nbsp;удобство тренировок.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr8.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Для продвинутых</div>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>6 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 45&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p7x" name="p7x" />
                                    <label htmlFor="p7x">Как всё устроено <p>Курс для тех, кто хочет заметно улучшить форму ягодиц, не&nbsp;считая калории и&nbsp;качественно проработать ягодичные мышцы.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn7x" name="pn7x" />
                                        <label htmlFor="pn7x">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Увеличишь объем, округлость и&nbsp;форму ягодиц</li>
                                                <li>Подтянешь и&nbsp;укрепишь мышцы ног</li>
                                                <li>Избавишься от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Этот курс предназначен для тех, кто хочет комплексно проработать зону ягодиц и&nbsp;ног, чтобы заметно улучшить форму и&nbsp;нарастить объем.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr7.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Подтянуть тело</div>
                                        <div className={styles.programs_tag}>Быстро похудеть</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Убираем<br/> лишний жир</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 1&nbsp;месяц</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по 1,5-3&nbsp;кг</li>
                                    <li><b>Длительность тренировок:</b> 40-50&nbsp;минут</li>
                                    <li><b>График тренировок:</b> через&nbsp;день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p4x" name="p4x" />
                                    <label htmlFor="p4x">Как всё устроено <p>Марафон подойдёт тем, кто хочет <b>быстро сбросить лишний вес, сделать тело более подтянутым</b> и&nbsp;рельефным. В&nbsp;программе также&nbsp;— рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn4x" name="pn4x" />
                                        <label htmlFor="pn4x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>В максимально короткие сроки избавишься от&nbsp;лишнего веса без&nbsp;ущерба для здоровья</li>
                                                <li>Сформируешь более подтянутое, стройное и&nbsp;рельефное тело</li>
                                                <li>Ускоришь метаболизм и&nbsp;общее состояние здоровья</li>
                                                <li>Начнешь правильно питаться и&nbsp;научишься поддерживать вес</li>
                                                <li>Изучишь техники самомассажа для активации обменных процессов и&nbsp;ускорения сжигания жира</li>
                                            </ul>
                                            <p>Марафон предназначен для тех, кто стремится к&nbsp;быстрым и&nbsp;заметным результатам в&nbsp;снижении веса и&nbsp;формировании подтянутого тела. Программа включает в&nbsp;себя не&nbsp;только эффективные тренировки, но&nbsp;и&nbsp;рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа. Это делает его комплексным и&nbsp;эффективным инструментом для достижения фитнес-целей.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr10.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Восстановление</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>курс <span>Восстановление после родов</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 60&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> коврик, гантели 2&nbsp;кг и&nbsp;фитнес-резинки с&nbsp;сопротивлением до&nbsp;5&nbsp;кг, утяжелители 1,5-3&nbsp;кг&nbsp;&mdash; по&nbsp;желанию</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> через день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p14x" name="p14x" />
                                    <label htmlFor="p14x">Как всё устроено <p>Ты&nbsp;недавно стала мамой и&nbsp;стремишься вернуться к&nbsp;своей прежней форме. Хочешь избавиться от&nbsp;лишних килограммов и&nbsp;восстановить тонус мышц после беременности.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn14x" name="pn14x" />
                                        <label htmlFor="pn14x">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Похудеешь и&nbsp;восстановишь форму</li>
                                                <li>Укрепишь мышцы брюшного пресса и&nbsp;тазового дна</li>
                                                <li>Подтянешь тело</li>
                                            </ul>
                                            <p>Курс поможет вернуться после родов в&nbsp;прежнюю форму: сбросить лишние килограммы, подтянуть тело и&nbsp;укрепить мышцы тазового дна. Включает рекомендации по&nbsp;питанию для кормящих мам.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/pr9.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Наладить питание</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>КУРС <span>ПИТАНИЕ</span></div>
                                    <div className={styles.programs_img__info_price}>10 000 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 45&nbsp;дней</li>
                                    <li><b>Инвентарь:</b> без инвентаря</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p11x" name="p11x" />
                                    <label htmlFor="p11x">Как всё устроено <p>Курс для тех, кто хочет научиться самостоятельно составлять сбалансированный рацион питания под свои цели, привить себе привычку всегда питаться правильно.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn11x" name="pn11x" />
                                        <label htmlFor="pn11x">Результаты после курса 
                                            <div>
                                            <ul>
                                                <li>Научишься самостоятельно составлять сбалансированный рацион питания, который соответствует твоим потребностям и&nbsp;целям</li>
                                                <li>Научишься правильно распределять белки, жиры и&nbsp;углеводы в&nbsp;рационе</li>
                                                <li>Улучшишь общее состояния здоровья, уровень энергии и&nbsp;физическую форму</li>
                                                <li>Избавишься от&nbsp;необходимости обращаться к&nbsp;диетологу или нутрициологу благодаря полученным знаниям и&nbsp;навыкам</li>
                                                <li>Повысишь самодисциплину и&nbsp;контроль над питанием, научишься постоянно поддерживать вес</li>
                                            </ul>
                                            <p>Курс поможет самостоятельно создать здоровый и&nbsp;сбалансированный рацион питания, который будет соответствовать твоим целям и&nbsp;потребностям. Полученные знания и&nbsp;навыки помогут контролировать свое питание и&nbsp;поддерживать здоровый образ жизни на&nbsp;долгосрочной основе.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div className={styles.btns}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Забрать набор</p>
                    </div>
                    <div className={styles.btns_prices}>
                        <div className={styles.btns_prices_top}>
                            <p>за&nbsp;4&nbsp;990&nbsp;₽</p>
                            <span>66&nbsp;090&nbsp;₽</span>
                        </div>
                        <div className={styles.btns_prices_bottom}>Выгода 92%!</div>
                    </div>
                </div> */}
        
        </div>
    )
}

export default Programs;
import { useEffect, useState, useCallback } from "react";
import jwtDecode from "jwt-decode";
import _debounce from "lodash/debounce";

const jwtChangedEventName = "jwt-changed";
const jwtChangedEvent = new Event(jwtChangedEventName);

const dispatchJwtChangedEvent = _debounce(() => {
  window.dispatchEvent(jwtChangedEvent);
}, 100);

export function getJWT() {
  if (typeof window === 'undefined') {
    return null;
  }
  
  return localStorage.getItem("jwt");
}

export function setJWT(jwt) {
  if (!jwt) {
    localStorage.removeItem("jwt");
  } else {
    localStorage.setItem("jwt", jwt);
  }

  dispatchJwtChangedEvent();
}

export function hasJWT() {
  return !!getJWT();
}

export function getUserData() {
  const jwt = getJWT();
  if (!jwt) {
    return null;
  }

  try {
    return jwtDecode(jwt);
  } catch (e) {
    throw new Error();
  }
}

export function useIsAuthorized() {
  const [hasToken, setHasToken] = useState(hasJWT());

  useEffect(() => {
    const onJWTChange = () => {
      setHasToken(hasJWT());
    };

    window.addEventListener(jwtChangedEventName, onJWTChange);
    return () => {
      window.removeEventListener(jwtChangedEventName, onJWTChange);
    };
  });

  return hasToken;
}

export function useUserData(fallback) {
  const [data, setData] = useState(null);

  const fetchUserData = useCallback(() => {
    try {
      setData(getUserData());
    } catch (e) {
      setJWT(null);
      typeof fallback === "function" && fallback();
    }
  }, [fallback]);

  useEffect(() => {
    fetchUserData();

    const onJWTChange = () => {
      fetchUserData();
    };

    window.addEventListener(jwtChangedEventName, onJWTChange);
    return () => {
      window.removeEventListener(jwtChangedEventName, onJWTChange);
    };
  }, [fetchUserData]);

  return data;
}



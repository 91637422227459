import { useState } from 'react';
import styles from './students.module.css';

const Students = ({scroll}) => {
    const [items, setItems] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/9.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/10.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/11.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/12.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/13.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/14.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/15.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/16.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/17.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/18.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/19.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/20.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/9.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/10.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/11.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/12.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/13.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/14.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/15.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/16.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/17.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/18.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/19.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/20.jpg'},
    ]);

    const [items2, setItems2] = useState([
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/31.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/32.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/33.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/34.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/21.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/22.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/23.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/24.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/25.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/26.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/27.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/28.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/29.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/30.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/8.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/31.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/32.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/33.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/34.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/21.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/22.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/23.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/24.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/25.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/26.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/27.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/28.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/29.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/30.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/3.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/6.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/1.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/2.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/4.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/5.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/7.jpg'},
        {src: 'https://gymteam.kinescopecdn.net/img/l/2024/method/gallery/8.jpg'},
    ]);

    const [isModal, setIsModal] = useState(false)
    const [activeSlide, setActiveSlide] = useState(null)

    return (
        <div className={styles.students}>
            <div className="container">
                <div className={styles.students_title}>Уже более <span>310&nbsp;000</span> человек достигли<br/> желаемого результата <span>вместе с&nbsp;Катей</span></div>
            </div>

            <div className={`${styles.students_row} ${styles.students_row1}`}>
                {items.map((item, idx) => 
                    <div className={styles.students_item} key={item.idx} onClick={() => (setActiveSlide(items[idx].src), setIsModal(true))}>
                        <img src={item.src} alt="" />
                    </div>
                )}
            </div>
            <div className={`${styles.students_row} ${styles.students_row2}`}>
                {items2.map((item, idx) => 
                    <div className={styles.students_item} key={item.idx} onClick={() => (setActiveSlide(items2[idx].src), setIsModal(true))}>
                        <img src={item.src} alt="" />
                    </div>
                )}
            </div>
            
            <div className={`${styles.modal} ${isModal && styles.modal_active}`}>
                <div className={styles.close_btn} onClick={()=>setIsModal(false)}>
                    <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1949 1.48389L1.33203 15.3468M1.33203 1.48389L15.1949 15.3468" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className={styles.modal_img}>
                    <img src={activeSlide} alt="" />
                </div>
            </div>
            
            <div className={styles.promo_btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                        <p>Хочу результат к&nbsp;лету</p>
            </div>
        </div>
    )
}

export default Students;
import styles from './programs.module.css';
import Countdown from 'react-countdown';

const Programs = ({scroll, date, renderer, chooseFunc, activeProgs, progs}) => {
    return (
        <>
        <div className={styles.programs}>
            <div className="container">
                
                <div className={styles.t1}>марафоны <div className={styles.prices_over_btn__discount}><span>-65%</span></div></div>
                <div className={styles.st1}>Выберите любой марафон, чтобы получить ощутимые<br/> результаты в&nbsp;сжатые сроки</div>
                <div className={styles.dts}>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/clock.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 2&nbsp;месяца</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/calendar.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Старт&nbsp;&mdash; сразу после оплаты</div>
                    </div>
                </div>
                </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>6 490 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                <p>Для новичков и&nbsp;тех, кто уже обладает некоторым опытом. Программа поможет быстро похудеть и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p> 
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Как все устроено 
                                            <p>
                                                <ul>
                                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                                    <li><b>Без инвентаря</b></li>
                                                    <li><b>Длительность тренировок:</b> 20-40&nbsp;мин.</li>
                                                    <li><b>График тренировок:</b> ежедневно</li>
                                                </ul>
                                            </p>
                                        </label>
                                    </div>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn1" name="pn1" />
                                        <label htmlFor="pn1">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Похудеете минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянете руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшите самочувствие и&nbsp;повысите уровень энергии</li>
                                                <li>Разберетесь в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаете фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>

                                    {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                    <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 1) && styles.programs_order_active}`} onClick={() => chooseFunc(0)}>
                                        <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>2190&nbsp;₽ <span>6490&nbsp;₽</span></div></div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/8/pup.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>6 490 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                            <p>Для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p>   
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5" name="p5" />
                                    <label htmlFor="p5">Как все устроено 
                                        <p>
                                            <ul>
                                                <li><b>Длительность:</b> 21&nbsp;день</li>
                                                <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                                <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                                <li><b>График тренировок:</b> ежедневно</li>
                                            </ul>
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn5" name="pn5" />
                                        <label htmlFor="pn5">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                                      {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                      <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 2) && styles.programs_order_active}`} onClick={() => chooseFunc(1)}>
                                            <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>2190&nbsp;₽ <span>6490&nbsp;₽</span></div></div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr7.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>9 990 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                            <p>Для тех, кто хочет добавить объём ягодицам, качественно проработать зону, заметно улучшить форму и&nbsp;тонус ягодичных мышц.</p>  
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="pt2" name="pt2" />
                                    <label htmlFor="pt2">Как все устроено 
                                        <p>
                                            <ul>
                                                <li><b>Длительность:</b> 30&nbsp;дней</li>
                                                <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                                <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                                <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                            </ul>
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pnt2" name="pnt2" />
                                        <label htmlFor="pnt2">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Увеличите объем и&nbsp;форму ягодиц</li>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                                      {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                        <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 3) && styles.programs_order_active}`} onClick={() => chooseFunc(2)}>
                                            <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>3390&nbsp;₽ <span>9990&nbsp;₽</span></div></div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от животика</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>6 490 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                            <p>Если хотите хорошо проработать мышцы пресса и&nbsp;создать красивый рельеф на&nbsp;животике.</p>
                               
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p55" name="p55" />
                                    <label htmlFor="p55">Как все устроено 
                                        <p>
                                            <ul>
                                                <li><b>Длительность:</b> 21&nbsp;день</li>
                                                <li><b>Без инвентаря</b></li>
                                                <li><b>Длительность тренировок:</b> 15-30&nbsp;мин.</li>
                                                <li><b>График тренировок:</b> ежедневно</li>
                                            </ul>                                        
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn55" name="pn55" />
                                        <label htmlFor="pn55">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Уменьшите объемы талии и&nbsp;живота</li>
                                                <li>Повысите мышечный тонус всего тела и&nbsp;подтянете мышцы пресса</li>
                                                <li>Снизите процент жира в&nbsp;области живота</li>
                                                <li>Улучшите осанку и&nbsp;усилите поддержку спины</li>
                                                <li>Повысите общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретете знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                                        {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                        <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 4) && styles.programs_order_active}`} onClick={() => chooseFunc(3)}>
                                            <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>2190&nbsp;₽ <span>6490&nbsp;₽</span></div></div>
                                        </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr4.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Подтянуть тело</div>
                                        <div className={styles.programs_tag}>Быстро похудеть</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Убираем<br/> лишний жир</span></div>
                                    <div className={styles.programs_img__info_price}>7 900 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 1&nbsp;месяц</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по 1,5-3&nbsp;кг</li>
                                    <li><b>Длительность тренировок:</b> 40-50&nbsp;минут</li>
                                    <li><b>График тренировок:</b> через&nbsp;день</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p4" name="p4" />
                                    <label htmlFor="p4">Как все устроено <p>Марафон подойдёт тем, кто хочет <b>быстро сбросить лишний вес, сделать тело более подтянутым</b> и&nbsp;рельефным. В&nbsp;программе также&nbsp;— рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn4" name="pn4" />
                                        <label htmlFor="pn4">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>В максимально короткие сроки избавитесь от&nbsp;лишнего веса без&nbsp;ущерба для здоровья</li>
                                                <li>Сформируете более подтянутое, стройное и&nbsp;рельефное тело</li>
                                                <li>Ускорите метаболизм и&nbsp;общее состояние здоровья</li>
                                                <li>Начнете правильно питаться и&nbsp;научитесь поддерживать вес</li>
                                                <li>Изучите техники самомассажа для активации обменных процессов и&nbsp;ускорения сжигания жира</li>
                                            </ul>
                                            <p>Марафон предназначен для тех, кто стремится к&nbsp;быстрым и&nbsp;заметным результатам в&nbsp;снижении веса и&nbsp;формировании подтянутого тела. Программа включает в&nbsp;себя не&nbsp;только эффективные тренировки, но&nbsp;и&nbsp;рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа. Это делает его комплексным и&nbsp;эффективным инструментом для достижения фитнес-целей.</p>
                                            </div>
                                        </label>
                                    </div>

                                    <div className={styles.programs_order_prices}>3140&nbsp;₽ <span>7900&nbsp;₽</span></div>
                                    <div className={styles.programs_order_title}>Выберите дату старта программы</div>
                                    <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 5) && styles.programs_order_active}`} onClick={() => chooseFunc(4)}>
                                        <div className={styles.programs_order_info}>16&nbsp;декабря</div>
                                    </div>
                                    <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 15) && styles.programs_order_active}`} onClick={() => chooseFunc(14)}>
                                        <div className={styles.programs_order_info}>13&nbsp;января</div>
                                    </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr3.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>9 990 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                            <p>Для тех, кто хочет избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи, улучшить качество тела. Внутри&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;техники самомассажа.</p>
                                
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p6" name="p6" />
                                    <label htmlFor="p6">Как все устроено 
                                        <p>
                                            <ul>
                                                <li><b>Длительность:</b> 30&nbsp;дней</li>
                                                <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                                <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                                <li><b>График тренировок:</b> через день</li>
                                            </ul>
                                    </p>
                                </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6" name="pn6" />
                                        <label htmlFor="pn6">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Избавитесь от&nbsp;целлюлита и&nbsp;Улучшите текстуру кожи</li>
                                                <li>Повысите тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнете контуры тела и&nbsp;сформируете более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоите техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысите уверенность в&nbsp;себе и&nbsp;улучшите самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                                      {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                      <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 5) && styles.programs_order_active}`} onClick={() => chooseFunc(4)}>
                                            <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>3290&nbsp;₽ <span>9990&nbsp;₽</span></div></div>
                                        </div>
                            </div>
                        </div>
                    </div> 
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/9/pup2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}><span>Универсальные тренировки на&nbsp;ягодицы</span></div>
                                    <div className={styles.programs_img__info_price}>6 490 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                            <p>Для тех, кто хочет набор эффективных упражнений на&nbsp;любой случай жизни&nbsp;&mdash; если болят колени, если всё тело ломит после тренировки или есть дискомфорт во&nbsp;время ПМС.</p>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p7" name="p7" />
                                    <label htmlFor="p7">Как все устроено 
                                        <p>
                                            <ul>
                                                <li><b>Длительность:</b> 10&nbsp;тренировок</li>
                                                <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                                <li><b>Где заниматься:</b> дома</li>
                                                <li><b>Длительность тренировок:</b> 15-20&nbsp;минут</li>
                                                <li><b>График тренировок:</b> ежедневно</li>
                                            </ul>
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn7" name="pn7" />
                                        <label htmlFor="pn7">Результаты после марафона 
                                            <div>
                                            <ul>
                                                <li>Укрепите и&nbsp;подтянете мышцы ягодиц</li>
                                                <li>Улучшите форму и&nbsp;контуры ягодиц</li>
                                                <li>Повысите выносливость и&nbsp;уровень энергии</li>
                                                <li>Улучшите самочувствие и&nbsp;Повысите энергию</li>
                                            </ul>
                                            <p>Марафон представляет собой уникальный конструктор из&nbsp;10&nbsp;тренировок на&nbsp;ягодицы, которые можно комбинировать в&nbsp;соответствии с&nbsp;твоими потребностями, физической подготовкой и&nbsp;настроением. Он&nbsp;разработан для того, чтобы обеспечить максимальную эффективность и&nbsp;удобство тренировок.</p>
                                            </div>
                                        </label>
                                    </div>
                                      {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                      <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 6) && styles.programs_order_active}`} onClick={() => chooseFunc(5)}>
                                            <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>2190&nbsp;₽ <span>6490&nbsp;₽</span></div></div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
               {/* <div className={styles.prices_over_btn}><div className={styles.prices_over_btn__price}><span>от&nbsp;748&nbsp;₽/мес.</span></div></div>
                <div className={styles.prices_over_btn}>
                    <div className={styles.prices_over_btn__price}><b>4&nbsp;490&nbsp;₽</b></div>
                    <div className={styles.prices_over_btn__old_price}>17&nbsp;500&nbsp;₽</div>
                    <div className={styles.prices_over_btn__discount}><span>-74%</span></div>
                </div>
                <div className={styles.btns}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <p>Забрать марафоны-бестселлеры</p>
                    </div>
                    <div className={styles.btns_prices}>
                        <div className={styles.btns_prices_top}>
                            <p>за&nbsp;6&nbsp;990&nbsp;₽</p>
                            <span>58&nbsp;190&nbsp;₽</span>
                        </div>
                        <div className={styles.btns_prices_bottom}>С&nbsp;ВЫГОДОЙ&nbsp;80%</div>
                    </div>
                </div>
                <div className={styles.programs_countdown}>
                    <p>Специальное предложение<br/> сгорит через:</p>
                    <div className={styles.programs_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div> */}
                </div>
                <div className={styles.programs}>
                    <div className="container">
                    <div className={styles.t1}>курсы <div className={styles.prices_over_btn__discount}><span>-65%</span></div></div>
                        <div className={styles.st1}>Выберите любой курс для комплексной и&nbsp;глубокой<br/> работы с&nbsp;телом</div>
                        <div className={styles.dts}>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/clock.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 4&nbsp;месяца</div>
                            </div>
                            <div className={styles.dts_item}>
                                <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/calendar.svg" alt="" /></div>
                                <div className={styles.dts_item_text}>Старт&nbsp;&mdash; сразу после оплаты</div>
                            </div>
                        </div>
                        </div>
                        <div className={styles.programs_block + ' ' + styles.programs_block_cours}>
                            <div className={styles.programs_item}>
                                    <div className={styles.programs_info}>
                                        <div className={styles.programs_img}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr7.png" alt="" />
                                            <div className={styles.programs_img__info}>
                                                <div className={styles.programs_tags}>
                                                    <div className={styles.programs_tag}>Накачать ягодицы</div>
                                                </div>
                                                <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 2.0</span></div>
                                                <div className={styles.programs_img__info_price}>7 990 <span>₽</span></div>
                                            </div>
                                        </div>
                                        <div className={styles.programs_text}>
                                        <p>Для тех, кто хочет комплексно проработать зону ягодиц и&nbsp;ног, чтобы заметно улучшить форму и&nbsp;нарастить объем</p>
                                            
                                            <div className={styles.programs_more}>
                                                <input type="checkbox" id="pt22" name="pt22" />
                                                <label htmlFor="pt22">Как все устроено 
                                                    <p>
                                                        <ul>
                                                            <li><b>Длительность:</b> 45&nbsp;дней</li>
                                                            <li><b>Инвентарь:</b> коврик, штанга/бодибар от&nbsp;8&nbsp;кг, фитнес-резинки  с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по&nbsp;1,5-3&nbsp;кг</li>
                                                            <li><b>Программа:</b> классы массонабора и&nbsp;сушки с&nbsp;учетом проблемных зон</li>
                                                            <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                                            <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                                        </ul>
                                                    </p>
                                                </label>
                                            </div>
                                            <div className={styles.programs_more}>
                                                    <input type="checkbox" id="pnt22" name="pnt22" />
                                                    <label htmlFor="pnt22">Результаты после курса 
                                                        <div>
                                                        <ul>
                                                            <li>Увеличите объем и&nbsp;форму ягодиц</li>
                                                            <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                            <li>Избавитесь от&nbsp;целлюлита</li>
                                                        </ul>
                                                        <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                  {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                                  <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 7) && styles.programs_order_active}`} onClick={() => chooseFunc(6)}>
                                                    <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>2790&nbsp;₽ <span>7990&nbsp;₽</span></div></div>
                                                </div>
                                        </div>
                                    </div>
                            </div>
                            <div className={styles.programs_item}>
                                    <div className={styles.programs_info}>
                                        <div className={styles.programs_img}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr8.png" alt="" />
                                            <div className={styles.programs_img__info}>
                                                <div className={styles.programs_tags}>
                                                    <div className={styles.programs_tag}>Для продвинутых</div>
                                                    <div className={styles.programs_tag}>Накачать ягодицы</div>
                                                </div>
                                                <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 3.0</span></div>
                                                <div className={styles.programs_img__info_price}>14 990 <span>₽</span></div>
                                            </div>
                                        </div>
                                        <div className={styles.programs_text}>
                                            <p>Для тех, кто хочет добавить объём и&nbsp;изменить форму ягодиц, ищите комплексный подход к&nbsp;формированию идеальной попы.</p>
                                            <div className={styles.programs_more}>
                                                <input type="checkbox" id="pt5" name="pt5" />
                                                <label htmlFor="pt5">Как все устроено 
                                                <p>
                                                    <ul>
                                                        <li><b>Длительность:</b> 90&nbsp;дней</li>
                                                        <li><b>Инвентарь:</b> бодибар или штанга весом от&nbsp;8&nbsp;кг, гантели разборные до&nbsp;15&nbsp;кг, утяжелители по&nbsp;1,5-3&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг</li>
                                                        <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                                        <li><b>программа:</b> 6&nbsp;недель массонабора, 6&nbsp;недель сушки в&nbsp;проблемных зонах</li>
                                                        <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                                    </ul>
                                                </p>
                                                </label>
                                            </div>
                                            <div className={styles.programs_more}>
                                                    <input type="checkbox" id="pnt5" name="pnt5" />
                                                    <label htmlFor="pnt5">Результаты после курса 
                                                        <div>
                                                        <ul>
                                                            <li>Увеличите объем и&nbsp;округлость ягодиц</li>
                                                            <li>Улучшите форму и&nbsp;симметрию ягодиц</li>
                                                            <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                            <li>Уменьшите проблемные зоны и&nbsp;несовершенства</li>
                                                            <li>Повысьте уверенность в&nbsp;себе и&nbsp;самооценку</li>
                                                        </ul>
                                                        <p>Этот курс предназначен для тех, кто хочет преобразить свои ягодицы и&nbsp;достичь идеальной формы и&nbsp;объема. Благодаря точечным упражнениям и&nbsp;индивидуальному подходу к&nbsp;тренировкам, вы&nbsp;сможешь достичь желаемых результатов и&nbsp;получить попу, о&nbsp;которой мечтали.</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                  {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                                  <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 8) && styles.programs_order_active}`} onClick={() => chooseFunc(7)}>
                                                    <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>5190&nbsp;₽ <span>14990&nbsp;₽</span></div></div>
                                                </div>
                                        </div>
                                    </div>
                            </div>
                            <div className={styles.programs_item}>
                                <div className={styles.programs_info}>
                                        <div className={styles.programs_img}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/pr6.png" alt="" />
                                            <div className={styles.programs_img__info}>
                                                <div className={styles.programs_tags}>
                                                    <div className={styles.programs_tag}>Похудеть</div>
                                                    <div className={styles.programs_tag}>Придать телу рельеф</div>
                                                </div>
                                                <div className={styles.programs_img__info_title}>КУРС <span>Жиросжигающий</span></div>
                                                <div className={styles.programs_img__info_price}>9 490 <span>₽</span></div>
                                            </div>
                                        </div>
                                        <div className={styles.programs_text}>
                                        <p>Если хотите максимально быстро похудеть и&nbsp;привести мышцы в&nbsp;тонус. Сразу 3&nbsp;уровня сложности в&nbsp;одной программе.</p>
                                            <div className={styles.programs_more}>
                                                <input type="checkbox" id="pd2" name="pd2" />
                                                <label htmlFor="pd2">Как все устроено 
                                                    <p>
                                                        <ul>
                                                            <li><b>Длительность:</b> 45&nbsp;дней</li>
                                                            <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                                            <li><b>Группы:</b> умеренный, интенсивный и силовой классы</li>
                                                            <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                                        </ul>
                                                    </p>
                                                </label>
                                            </div>
                                            <div className={styles.programs_more}>
                                                    <input type="checkbox" id="pnd2" name="pnd2" />
                                                    <label htmlFor="pnd2">Результаты после курса 
                                                        <div>
                                                        <ul>
                                                            <li>Снизите процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                            <li>Станете выносливее и&nbsp;улучшите физическую форму благодаря интенсивным тренировкам.</li>
                                                            <li>Усилите мышечный корсет и&nbsp;осанку</li>
                                                            <li>Уменьшите объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                            <li>Нормализуете питание</li>
                                                        </ul>
                                                        <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                 {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                                 <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 9) && styles.programs_order_active}`} onClick={() => chooseFunc(8)}>
                                                    <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>3390&nbsp;₽ <span>9490&nbsp;₽</span></div></div>
                                                </div>
                                        </div>
                                </div>
                            </div>
                            <div className={styles.programs_item}>
                                <div className={styles.programs_info}>
                                        <div className={styles.programs_img}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/9/food.png" alt="" />
                                            <div className={styles.programs_img__info}>
                                                <div className={styles.programs_tags}>
                                                    <div className={styles.programs_tag}>Похудеть</div>
                                                    <div className={styles.programs_tag}>Придать телу рельеф</div>
                                                </div>
                                                <div className={styles.programs_img__info_title}>КУРС <span>питание</span></div>
                                                <div className={styles.programs_img__info_price}>12 990 <span>₽</span></div>
                                            </div>
                                        </div>
                                        <div className={styles.programs_text}>
                                        <p>Узнаете как правильно питаться дома, в&nbsp;кафе, гостях и&nbsp;как составить рацион под свою цель без подсчета КБЖУ.</p>
                                            <div className={styles.programs_more}>
                                                <input type="checkbox" id="pd8" name="pd8" />
                                                <label htmlFor="pd8">Как все устроено 
                                                    <p>
                                                        <ul>
                                                            <li><b>Длительность:</b> 45&nbsp;дней</li>
                                                            <li><b>Инвентарь:</b> без инвертаря</li>
                                                            <li><b>График:</b> ежедневно</li>
                                                        </ul>
                                                    </p>
                                                </label>
                                            </div>
                                            <div className={styles.programs_more}>
                                                    <input type="checkbox" id="pnd9" name="pnd9" />
                                                    <label htmlFor="pnd9">Результаты после курса 
                                                        <div>
                                                        <ul>
                                                            <li>Снизите процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                            <li>Станете выносливее и&nbsp;улучшите физическую форму благодаря интенсивным тренировкам.</li>
                                                            <li>Усилите мышечный корсет и&nbsp;осанку</li>
                                                            <li>Уменьшите объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                            <li>Нормализуете питание</li>
                                                        </ul>
                                                        <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                                        </div>
                                                    </label>
                                                </div>
                                                {/* <div className={styles.programs_order_title}>Выберите дату старта программы</div> */}
                                                <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 10) && styles.programs_order_active}`} onClick={() => chooseFunc(9)}>
                                                    <div className={styles.programs_order_info}><div className={styles.programs_order_prices}>4390&nbsp;₽ <span>12990&nbsp;₽</span></div></div>
                                                </div>
                                        </div>
                                </div>
                            </div>
                            
                        </div>
                        {/* <div className={styles.prices_over_btn}><div className={styles.prices_over_btn__price}><span>1 081,5&nbsp;₽/мес</span></div></div>
                        <div className={styles.prices_over_btn}><div className={styles.prices_over_btn__price}><b>6&nbsp;490&nbsp;₽</b></div><div className={styles.prices_over_btn__old_price}>32&nbsp;400&nbsp;₽</div></div>                
                        <div className={styles.btns}>
                            <div className={styles.promo_btn} onClick={scroll}>
                                <p>забрать комплект программ</p>
                            </div>
                            <div className={styles.btns_prices}>
                                <div className={styles.btns_prices_top}>
                                    <p>за&nbsp;4&nbsp;990&nbsp;₽</p>
                                    <span>32&nbsp;900&nbsp;₽</span>
                                </div>
                                <div className={styles.btns_prices_bottom}>С&nbsp;ВЫГОДОЙ&nbsp;80%</div>
                            </div>
                        </div> */}   
                </div>
        </>
    )
}

export default Programs;
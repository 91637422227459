import React from 'react';
import { SalesWidget } from "@com.marathonium/web2-ui";

export const L2024_12_checkform = () => {
    
    return (
      <>
        <h1 style={{textAlign: 'center'}}>CHECK FORM</h1>
        <SalesWidget id={window.lps_settings.forms.checkform_id} />
      </>
    );
};
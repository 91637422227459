import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import styles from './start.module.css';
import { useState } from 'react';

const Start = ({date, renderer, chooseFunc, activeProgs, progs}) => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();

    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

                <div className={styles.start_block}>
                <div className={styles.start_block_pretitle}>ПОСЛЕДНЕЕ ПРЕДЛОЖЕНИЕ РАСПРОДАЖИ!</div>
                    <div className={styles.start_block_title}>Успейте забрать любые фитнес-<br/>программы Кати <span>с&nbsp;выгодой&nbsp;65%</span></div>
                    {/* <div className={styles.start_block_subtitle}>Действует только <span>до 23:59</span></div> */}
                    <div className={styles.start_block_subtitle}>При покупке 2&nbsp;и&nbsp;более программ вы&nbsp;получаете<br/> в&nbsp;подарок программу питания стоимостью <span>10&nbsp;000&nbsp;₽</span></div>
                    {/* <div className={styles.n_dates}>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/8/dts1.svg" alt="" />
                            <p>Доступ&nbsp;5&nbsp;месяцев</p>
                        </div>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/8/calendar.svg" alt="" />
                            <p>Старт&nbsp;&mdash; сразу после оплаты</p>
                        </div>
                    </div> */}
                    <div className={styles.countdown_start}>
                        <div className={styles.start_block_subtitle}>Специальное предложение сгорит через:</div>
                        {/* <div className={styles.start_remains}>
                            <div>Осталось мест до&nbsp;повышения цены:</div> <span>{window.lps_settings.other.count}/100</span>
                        <span className={styles.black}></span>
                        </div> */}
                        <div className={styles.promo_countdown}>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                    

                    <div className={styles.chose}>
                        <div className={`${styles.chose_item}`}>
                            {/* <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}>3&nbsp;марафона Кати Усмановой</div>
                                <div className={styles.chose_head_prices}>
                                    <p>от&nbsp;748&nbsp;₽/мес</p>
                                    <div className={styles.chose_head_price}><span>17&nbsp;500&nbsp;₽</span>4&nbsp;490&nbsp;₽
                                        <div className={styles.sale}>-80%</div>
                                    </div>
                                </div>
                                
                            </div> */}
                            {/* <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}><b>Марафон</b> &laquo;Стройность&nbsp;1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}><b>Марафон</b> &laquo;Упругой попы&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}><b>Марафон</b> &laquo;Антицеллюлитный&raquo;</div>
                                <div className={styles.chose_list_price}>7500&nbsp;₽</div>
                            </div> */}
                             {/* <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Марафон &laquo;Убираем лишний жир&raquo;</div>
                                        <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div>
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 5) && styles.radio_item_c_active}`} onClick={() => chooseFunc(4)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>2990&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>9990&nbsp;₽</div>
                                </div>
                            </div> */}

                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Марафон &laquo;Стройность 1.0&raquo;</div>
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 1) && styles.radio_item_c_active}`} onClick={() => chooseFunc(0)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>2190&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>6490&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Марафон &laquo;Упругой попы 1.0&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 2) && styles.radio_item_c_active}`} onClick={() => chooseFunc(1)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>2190&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>6490&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Марафон &laquo;Упругой попы 2.0&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 3) && styles.radio_item_c_active}`} onClick={() => chooseFunc(2)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>3390&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>9990&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Марафон &laquo;Плоского живота&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 4) && styles.radio_item_c_active}`} onClick={() => chooseFunc(3)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>2190&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>6490&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Марафон &laquo;Антицеллюлитный&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 5) && styles.radio_item_c_active}`} onClick={() => chooseFunc(4)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>3290&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>9990&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Универсальные тренировки на&nbsp;ягодицы</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 6) && styles.radio_item_c_active}`} onClick={() => chooseFunc(5)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>2190&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>6490&nbsp;₽</div>
                                </div>
                            </div>

                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Курс &laquo;Идеальной попы 2.0&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 5&nbsp;месяцев</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 7) && styles.radio_item_c_active}`} onClick={() => chooseFunc(6)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>2790&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>7990&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Курс &laquo;Идеальной попы 3.0&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 5&nbsp;месяцев</div>     */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 8) && styles.radio_item_c_active}`} onClick={() => chooseFunc(7)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>5190&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>14990&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Курс &laquo;Жиросжигающий&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 5&nbsp;месяцев</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 9) && styles.radio_item_c_active}`} onClick={() => chooseFunc(8)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>3390&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>9490&nbsp;₽</div>
                                </div>
                            </div>
                            <div className={styles.radio_item}>
                                <div className={styles.radio_item_left}>
                                    <div className={styles.radio_item_names}>
                                        <div className={styles.radio_item_name1}>Курс &laquo;Питание&raquo;</div>
                                        {/* <div className={styles.radio_item_name2}>Доступ 5&nbsp;месяцев</div> */}
                                    </div>
                                    <div className={styles.radio_item_choose}>
                                        <div className={`${styles.radio_item_c} ${activeProgs.find((el) => el.id === 10) && styles.radio_item_c_active}`} onClick={() => chooseFunc(9)}>
                                            <div className={styles.radio_item_name2}>Доступ 2&nbsp;месяца</div><p>|</p>Дата старта&nbsp;&mdash; сразу после оплаты
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.radio_item_right}>
                                    <div className={styles.radio_item_p1}>4390&nbsp;₽</div>
                                    <div className={styles.radio_item_p2}>12990&nbsp;₽</div>
                                </div>
                            </div>
                            

                                    {/* <div className={`${styles.programs_order} ${activeProgs.find((el) => el.id === 1) && styles.programs_order_active}`} onClick={() => chooseFunc(0)}> */}
                        </div>
                    </div>

                    {/* <div className={styles.inf}>
                        <div className={styles.inf_item}>
                            <p>Старт тренировок</p>
                            <p>20 мая</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/calendar.png" alt="" />
                        </div>
                        <div className={styles.inf_item}>
                            <p>Доступ к&nbsp;программам:</p>
                            <p>6 месяцев</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/clock.png" alt="" />
                        </div>
                    </div> */}

                    {/* <div className={styles.total}>
                        <span>6&nbsp;790&nbsp;₽</span>
                        <em>7&nbsp;990&nbsp;₽</em>
                        <p>Курс для зала от&nbsp;Кати Усмановой</p>
                    </div> */}
                    
                    <div className={styles.start_list}>
                        <div className={styles.plus}>+ 6&nbsp;гайдов в&nbsp;подарок <b>при оплате в&nbsp;течение 15-ти минут</b></div>
                        {/* <div className={styles.start_list_item}>
                            <p>+ участие в&nbsp;розыгрыше поездки в&nbsp;Дубай на&nbsp;двоих</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ возможность выиграть Стайлер Dyson и&nbsp;другие ценные призы</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ подписка на&nbsp;Gymteam на&nbsp;1&nbsp;месяц</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ 5&nbsp;гайдов для улучшения результата в&nbsp;подарок</p>
                            <p></p>
                        </div> */}
                    </div>

                    <div className="womens_day_2 scrollhere2">
                        <SalesWidget hook={widget} id={window.lps_settings.forms.womens_day2_2_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />
                    </div>
                    <div className={styles.pay}>
							<div className={styles.pay_item}>
								<img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/tinkoff.png" alt=""/>
								<p>Рассрочка без процентов от&nbsp;548&nbsp;₽ в&nbsp;месяц</p>
								<span>Вы&nbsp;сможете оформить заказ в&nbsp;рассрочку: на&nbsp;6&nbsp;месяцев без процентов и&nbsp;предварительного взноса. Только для граждан&nbsp;РФ.</span>
							</div>
							<div className={styles.pay_item}>
								<img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/doli.png" alt=""/>
								<p>Оплатите Долями, без банка, переплат и&nbsp;рисков</p>
								<span>Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите платёж на&nbsp;4&nbsp;части <b>от&nbsp;822&nbsp;₽.</b> Всего на&nbsp;6&nbsp;недель. Только для граждан&nbsp;РФ.</span>
							</div>
						</div>
                    {/* <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p> */}
                    {/* <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;699&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 1&nbsp;047,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={styles.pay}>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Тинькофф-рассрочка</div>
                                <div className={styles.pay_item_text}>Платите потом&nbsp;&mdash; первый платёж через 30&nbsp;дней <b>от&nbsp;748&nbsp;₽</b> в&nbsp;месяц. Без переплат, без процентов, до&nbsp;6&nbsp;месяцев. Только для граждан&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>По QR-коду</div>
                                <div className={styles.pay_item_text}>Отсканируйте камерой телефона QR-код и&nbsp;перейдите по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить оплату. Удобно со&nbsp;SberPay.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Долями</div>
                                <div className={styles.pay_item_text}>Разделите платёж на&nbsp;4&nbsp;равные части <b>от&nbsp;1247,5&nbsp;₽</b>. Всего на&nbsp;6&nbsp;недель. Без ожидания одобрения банка, переплат и&nbsp;рисков.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>PayPal</div>
                                <div className={styles.pay_item_text}>Введите только электронный адрес и&nbsp;пароль&nbsp;&mdash; не&nbsp;нужно вводить номер карты. Безопасный способ платы, доступный клиентам банков не&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Картой банка&nbsp;РФ<br/> или зарубежного</div>
                                <div className={styles.pay_item_text}>Можете пользоваться своей картой, где&nbsp;бы вы&nbsp;ни&nbsp;находились. Для оплаты картой иностранного банка, выберите оплату через Stripe.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Наличными в&nbsp;терминалах</div>
                                <div className={styles.pay_item_text}>Оплатите ваш заказ наличными через ближайший терминал. Быстро и&nbsp;удобно, без использования кредитных карт и&nbsp;необходимости раскрывать личные данные.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png" alt="" />
                            </div>
                        </div>
                    </div> */}
                </div>

                
            </div>
        </div>
        </>
    )
}

export default Start;
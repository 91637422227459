export const JsonRpcErrorCode = {
  Redirect: 303,
  Unauthorized: 401,

  AccessDenied: 403,
  NotFound: 404,
  Conflict: 409,

  ParseError: -32700,
  InvalidRequest: -32600,
  MethodNotFound: -32601,
  InvalidParams: -32602,
};

export class JsonRpcError extends Error {}

export function wrapJsonRpcError(e) {
  return new JsonRpcError(JSON.stringify(e));
}

export function unwrapJsonRpcError(e) {
  if (e instanceof JsonRpcError) {
    return JSON.parse(e.message);
  }

  return null;
}
import styles from './promo.module.css';
import Countdown from 'react-countdown';

const Promo = ({scroll, scroll2, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.main}>
                    <div className={styles.promo_right}>
                        <div className={styles.promo_pretitle}>МЕГА-РАСПРОДАЖА только на&nbsp;24&nbsp;часа</div>
                        <div className={styles.promo_title}>Создайте фигуру мечты&nbsp;дома <span>с&nbsp;выгодой&nbsp;88%</span></div>
                        <div className={styles.promo_subtitle}>С&nbsp;комплектом <b>из&nbsp;8&nbsp;самых эффективных фитнес-программ,</b><br/> чтобы быть стройной уже весной   </div>
                        <div className={styles.promo_mob_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/promo-mob5.jpg" alt="" className={styles.img_shadow}></img>
                        </div>
                        {/* <div className={styles.bottom_block}>
                            <div className={styles.prize}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/money.png"/>
                                <div className={styles.prize_text}>Самая большая выгода<br/> <span>для первых 100 оплативших</span> </div>
                            </div>
                        </div> */}
                        <div className={styles.buttons}>
                            <div className={styles.btn} onClick={scroll}>Забрать набор с&nbsp;выгодой</div>
                            <div className={`${styles.btn} ${styles.btn2}`} onClick={scroll2}>Узнать подробнее</div>
                        </div>
                        <div className={styles.countdown_timer}>
                            <p>Выгода сгорит через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                        {/* <div className={styles.promo_discount}>
                            <span className={styles.left}>Осталось комплектов: </span>
                            <span className={styles.count}><span>{window.lps_settings.other.count}/100</span></span>
                            <div className={styles.left_1}><span>до&nbsp;повышения цены</span></div>
                        </div>  */}
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo;
import Countdown from 'react-countdown';
import styles from './promo.module.css';
import { useState } from 'react';

const Promo = ({scroll, renderer}) => {
    const [date, setDate] = useState(Date.now() + 1200000)

    return (
        <div className={styles.promo}>
            <div className="container">
                {/* <div className={styles.header}>
                    <div className={styles.header_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/logo.svg" alt="" /></div>
                    <div className={styles.header_link} onClick={scroll}>Записаться на&nbsp;второй поток</div>
                </div> */}
                <div className={styles.main}>
                    <div className={styles.main_left}>
                        <div className={styles.text}>
                            <div className={styles.inf1}>
                                <p>Вы&nbsp;успешно записались на&nbsp;курс любви к&nbsp;себе!<br/> И&nbsp;теперь вам доступно:</p>
                            </div>
                            <div className={styles.t1}>
                                <p>Закрытое предложение</p>
                                <p>только на&nbsp;20&nbsp;минут</p>
                            </div>
                            <div className={styles.t2}>Создайте красивое и&nbsp;стройное тело,<br/> которое вы&nbsp;будете любить. Заберите<br/> набор из&nbsp;3&nbsp;фитнес-программ<br/> <b>с&nbsp;выгодой&nbsp;85%</b></div>
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo_mob_2.png" alt="" /> */}
                            <div className={styles.btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Записаться сейчас</p>
                            </div>
                        </div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/lks/promo.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/lks/promo.png" alt="" />
                            {/* <img src="https://gymteam.kinescopecdn.net/img/l/2025/lks/promo_mob.png" alt="" /> */}
                            <div className={styles.btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Записаться сейчас</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.main_right}>
                        <div className={styles.countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.countdown_timer}>
                                <Countdown date={date} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                </div>
                

                {/* <div className={styles.more}>
                    <div className={styles.more_title}>РЕШАЙСЯ ОСТАВАТЬСЯ<br/> В&nbsp;ОТЛИЧНОЙ ФОРМЕ ВСЕГДА!</div>
                    <div className={styles.more_subtitle}><b>Выбирай пакет программ под свои цели</b></div>
                    <div className={styles.more_subtitle}>Чтобы быстро и&nbsp;безопасно избавиться от&nbsp;лишнего веса,<br/> подтянуть все тело и&nbsp;попрощаться с&nbsp;несовершенствами кожи</div>
                    <div className={styles.more_btn} onClick={scroll}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu2/arrow.svg" alt="" />
                    </div>
                </div> */}
            </div>
        </div>
        
    )
}

export default Promo;
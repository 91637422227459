import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <>
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_tags}>
                        <div className={styles.promo_tag}>Последнее предложение распродажи!</div>
                        {/* <div className={styles.promo_tag}>Фитнес-девичник</div> */}
                    </div>
                    <div className={styles.promo_title}>Успейте забрать любые<br/> марафоны и&nbsp;курсы<br/> <span>с&nbsp;выгодой&nbsp;65%</span>
                    {/* <div><img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/ggg.svg" alt="" /> <p>-89%</p></div> */}
                    </div>
                    <div className={styles.promo_text}>Соберите собственный набор программ под свои<br/> цели и&nbsp;<b className={styles.b600}>создайте красивое и&nbsp;стройное тело</b></div>
                    <div className={styles.promo_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/promo2.png" alt="" />
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/promo_mob2.png" alt="" />
                        {/* <div className={styles.promo_prize}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womday/heart2.png" alt="" />
                            <p>Выиграй iPhone 15&nbsp;Pro,<br/> AirPods Max и&nbsp;другие подарки</p>
                        </div> */}
                    </div>
                    <div className={styles.promo_info}>
                        <div className={styles.promo_info_left}>
                            <div className={styles.promo_info_prize}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/womens_day/2/present01.png" alt="" />
                            </div>
                            <div className={styles.promo_info_desc}>
                                {/* <span>При покупке программ</span> */}
                                <p>При покупке от&nbsp;2-х программ<br/> марафон похудения в&nbsp;подарок!</p>
                            </div>
                        </div>
                        <div className={styles.promo_info_right}>
                            {/* <div className={styles.promo_info_oldprice}>47&nbsp;400&nbsp;₽&nbsp;<img src="https://gymteam.kinescopecdn.net/img/l/2024/womday/percent.png" alt="" /></div>
                            <div className={styles.promo_info_price}>4&nbsp;990&nbsp;₽</div> */}
                            {/* <div className={styles.promo_countdown}>
                                <p>Специальное предложение сгорит через:</p>
                                <div className={styles.promo_countdown_timer}>
                                    <Countdown date={Date.now() + date} renderer={renderer} />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className={styles.promo_btns}>
                        <div className={styles.promo_btn} onClick={scroll}>ВЫБРАТЬ ПРОГРАММЫ</div>
                        <div className={styles.promo_countdown}>
                            <p>Распродажа завершится через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                        {/* <div className={styles.promo_remain}>
                            <p>Осталось мест с&nbsp;наибольшей выгодой: <span>{window.lps_settings.other.count}/100</span></p>
                            <p><b>После предложение сгорит</b></p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

        
        </>
    )
}

export default Promo;
import styles from './programs.module.css';
import Countdown from 'react-countdown';

const Programs = ({scroll, date, renderer, progs}) => {
    return (
        <>
        <div className={`scrollhere2 ${styles.programs}`}>
            <div className="container">
                
                <div className={styles.t1}><span>6&nbsp;программ для фигуры мечты</span>, даже если<br/> нет времени и&nbsp;мотивации</div>
                <div className={styles.st1}>Проверенный пакет из&nbsp;тренировок, которые показали лучшие результаты<br/> у&nbsp;наших учениц и&nbsp;помогли им&nbsp;создать лучшую фигуру своей в&nbsp;жизни.</div>
                <div className={styles.dts}>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/clock.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Доступ&nbsp;&mdash; 6&nbsp;месяцев</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/calendar.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Старт тренировок&nbsp;&mdash; 4&nbsp;марта</div>
                    </div>
                    <div className={styles.dts_item}>
                        <div className={styles.dts_item_img}><img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/chat.svg" alt="" /></div>
                        <div className={styles.dts_item_text}>Чат с&nbsp;куратором&nbsp;&mdash; <span>С&nbsp;3&nbsp;ПО 31&nbsp;МАРТА</span></div>
                    </div>
                </div>
                </div>
                <div className={styles.programs_block}>
                    <div className={styles.programs_item}>
                            <div className={styles.programs_info}>
                                <div className={styles.programs_img}>
                                    <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr1.png" alt="" />
                                    <div className={styles.programs_img__info}>
                                        <div className={styles.programs_tags}>
                                            <div className={styles.programs_tag}>Похудеть</div>
                                            <div className={styles.programs_tag}>Придать телу рельеф</div>
                                        </div>
                                        <div className={styles.programs_img__info_title}>МАРАФОН <span>СТРОЙНОСТЬ 1.0</span></div>
                                        <div className={styles.programs_img__info_price}>6&nbsp;490 <span>₽</span></div>
                                    </div>
                                </div>
                                <div className={styles.programs_text}>
                                    <p>Для новичков и&nbsp;тех, кто уже обладает некоторым опытом. Программа поможет быстро похудеть и&nbsp;сформировать привычку правильно питаться и&nbsp;регулярно заниматься фитнесом.</p>
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="p1" name="p1" />
                                        <label htmlFor="p1">Как всё устроено
                                            <p> 
                                                <ul>
                                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                                    <li><b>Без инвентаря</b></li>
                                                    <li><b>Длительность тренировок:</b> 20-40&nbsp;мин.</li>
                                                    <li><b>График тренировок:</b> ежедневно</li>
                                                </ul>
                                            </p>
                                        </label>
                                    </div>  
                                    <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn1" name="pn1" />
                                        <label htmlFor="pn1">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Похудеете минимум на&nbsp;1&nbsp;размер</li>
                                                <li>Подтянете руки, живот, попу и&nbsp;ноги</li>
                                                <li>Улучшите самочувствие и&nbsp;повысите уровень энергии</li>
                                                <li>Разберетесь в&nbsp;системе сбалансированного питания</li>
                                                <li>Сделаете фитнес своей привычкой</li>
                                            </ul>
                                            <p>Марафон предназначен для того, чтобы достичь конкретных результатов в&nbsp;короткие сроки, следуя структурированной программе тренировок и&nbsp;рационального питания.</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                    {/* <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                            <div className={styles.programs_img_bg}></div>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr2.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 1.0</span></div>
                                    <div className={styles.programs_img__info_price}>6&nbsp;490 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <ul>
                                    <li><b>Длительность:</b> 21&nbsp;день</li>
                                    <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг</li>
                                    <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                    <li><b>График тренировок:</b> ежедневно</li>
                                </ul>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p5" name="p5" />
                                    <label htmlFor="p5">Для кого подойдёт <p>Марафон подойдет для тех, кто хочет добавить объём и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p></label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn5" name="pn5" />
                                        <label htmlFor="pn5">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr3.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Накачать ягодицы</div>
                                        <div className={styles.programs_tag}>Для продвинутых</div>
                                        
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Упругой попы 2.0</span></div>
                                    <div className={styles.programs_img__info_price}>9&nbsp;990 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <p>Для тех, кто хочет добавить объем и&nbsp;тонус ягодицам, заметно улучшить форму в&nbsp;короткий срок.</p>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="pt2" name="pt2" />
                                    <label htmlFor="pt2">Как всё устроено
                                        <p>
                                        <ul>
                                            <li><b>Длительность:</b> 30&nbsp;дней</li>
                                            <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт.&nbsp;по&nbsp;1,5-3&nbsp;кг</li>
                                            <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                            <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                        </ul>
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pnt2" name="pnt2" />
                                        <label htmlFor="pnt2">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Увеличите объем и&nbsp;форму ягодиц</li>
                                                <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                <li>Избавитесь от&nbsp;целлюлита</li>
                                            </ul>
                                            <p>Марафон поможет заметно улучшить форму ягодиц, избавиться от&nbsp;целлюлита, сделать ноги подтянутыми.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr4.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Укрепить пресс</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Плоского живота</span></div>
                                    <div className={styles.programs_img__info_price}>6&nbsp;490 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <p>Если хотите хорошо проработать мышцы пресса и&nbsp;создать красивый рельеф на&nbsp;животике.</p>
                                
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p55" name="p55" />
                                    <label htmlFor="p55">Как всё устроено
                                        <p>
                                        <ul>
                                            <li><b>Длительность:</b> 21&nbsp;день</li>
                                            <li><b>Без инвентаря</b></li>
                                            <li><b>Длительность тренировок:</b> 15-30&nbsp;мин.</li>
                                            <li><b>График тренировок:</b> ежедневно</li>
                                        </ul>
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn55" name="pn55" />
                                        <label htmlFor="pn55">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Уменьшите объемы талии и&nbsp;живота</li>
                                                <li>Повысите мышечный тонус всего тела и&nbsp;подтянете мышцы пресса</li>
                                                <li>Снизите процент жира в&nbsp;области живота</li>
                                                <li>Улучшите осанку и&nbsp;усилите поддержку спины</li>
                                                <li>Повысите общую выносливость организма и&nbsp;уровень энергии</li>
                                                <li>Приобретете знания в&nbsp;области питания и&nbsp;тренировок, которые будут полезны всегда</li>
                                            </ul>
                                            <p>Этот марафон подходит как начинающим, так и&nbsp;опытным спортсменам, так как программы могут быть адаптированы под разные уровни физической подготовки.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr5.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Подтянуть тело</div>
                                        <div className={styles.programs_tag}>Быстро похудеть</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Убираем<br/> лишний жир</span></div>
                                    <div className={styles.programs_img__info_price}>9&nbsp;990 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <p>Подойдёт тем, кто хочет быстро сбросить лишний вес, сделать тело более подтянутым и&nbsp;рельефным. В&nbsp;программе также&nbsp;&mdash; рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа.</p>
                                
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p4" name="p4" />
                                    <label htmlFor="p4">Как всё устроено 
                                        <p>
                                            <ul>
                                                <li><b>Длительность:</b> 1&nbsp;месяц</li>
                                                <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 2&nbsp;шт. по 1,5-3&nbsp;кг</li>
                                                <li><b>Длительность тренировок:</b> 40-50&nbsp;минут</li>
                                                <li><b>График тренировок:</b> через&nbsp;день</li>
                                            </ul>
                                        </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn4" name="pn4" />
                                        <label htmlFor="pn4">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>В максимально короткие сроки избавитесь от&nbsp;лишнего веса без&nbsp;ущерба для здоровья</li>
                                                <li>Сформируете более подтянутое, стройное и&nbsp;рельефное тело</li>
                                                <li>Ускорите метаболизм и&nbsp;общее состояние здоровья</li>
                                                <li>Начнете правильно питаться и&nbsp;научитесь поддерживать вес</li>
                                                <li>Изучите техники самомассажа для активации обменных процессов и&nbsp;ускорения сжигания жира</li>
                                            </ul>
                                            <p>Марафон предназначен для тех, кто стремится к&nbsp;быстрым и&nbsp;заметным результатам в&nbsp;снижении веса и&nbsp;формировании подтянутого тела. Программа включает в&nbsp;себя не&nbsp;только эффективные тренировки, но&nbsp;и&nbsp;рекомендации по&nbsp;питанию и&nbsp;уроки самомассажа. Это делает его комплексным и&nbsp;эффективным инструментом для достижения фитнес-целей.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                        <div className={styles.programs_info}>
                            <div className={styles.programs_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr6.png" alt="" />
                                <div className={styles.programs_img__info}>
                                    <div className={styles.programs_tags}>
                                        <div className={styles.programs_tag}>Похудеть</div>
                                        <div className={styles.programs_tag}>Избавиться от дряблости</div>
                                    </div>
                                    <div className={styles.programs_img__info_title}>МАРАФОН <span>Антицеллюлитный</span></div>
                                    <div className={styles.programs_img__info_price}>9&nbsp;990 <span>₽</span></div>
                                </div>
                            </div>
                            <div className={styles.programs_text}>
                                <p>Для тех, кто хочет избавиться от&nbsp;целлюлита и&nbsp;дряблости кожи, улучшить качество тела. Внутри&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;техники самомассажа.</p>
                                <div className={styles.programs_more}>
                                    <input type="checkbox" id="p6" name="p6" />
                                    <label htmlFor="p6">Как всё устроено
                                    <p>
                                        <ul>
                                            <li><b>Длительность:</b> 30&nbsp;дней</li>
                                            <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, 2&nbsp;утяжелителя по&nbsp;1,5-3&nbsp;кг. Для массажа потребуются вакуумная банка, скребок гуаша или обычная столовая ложка</li>
                                            <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                            <li><b>График тренировок:</b> через день</li>
                                        </ul>
                                    </p>
                                    </label>
                                </div>
                                <div className={styles.programs_more}>
                                        <input type="checkbox" id="pn6" name="pn6" />
                                        <label htmlFor="pn6">Результаты программы 
                                            <div>
                                            <ul>
                                                <li>Избавитесь от&nbsp;целлюлита и&nbsp;Улучшите текстуру кожи</li>
                                                <li>Повысите тонус и&nbsp;упругость кожи</li>
                                                <li>Подчеркнете контуры тела и&nbsp;сформируете более стройную и&nbsp;подтянутую фигуру</li>
                                                <li>Освоите техники самомассажа для улучшения кровообращения и&nbsp;лимфодренажа </li>
                                                <li>Повысите уверенность в&nbsp;себе и&nbsp;улучшите самочувствие</li>
                                            </ul>
                                            <p>Марафон создан для того, чтобы избавиться от&nbsp;целлюлита и&nbsp;повысить качество тела. Внутри программы&nbsp;&mdash; не&nbsp;только тренировки, но&nbsp;и&nbsp;обучение эффективным техникам самомассажа. Это делает марафон комплексным и&nbsp;эффективным средством для изменения качества тела.</p>
                                            </div>
                                        </label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.programs_item}>
                                    <div className={styles.programs_info}>
                                        <div className={styles.programs_img}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr7.png" alt="" />
                                            <div className={styles.programs_img__info}>
                                                <div className={styles.programs_tags}>
                                                    <div className={styles.programs_tag}>Для продвинутых</div>
                                                    <div className={styles.programs_tag}>Накачать ягодицы</div>
                                                </div>
                                                <div className={styles.programs_img__info_title}>КУРС <span>идеальной попы 3.0</span></div>
                                                <div className={styles.programs_img__info_price}>14&nbsp;990 <span>₽</span></div>
                                            </div>
                                        </div>
                                        <div className={styles.programs_text}>
                                            <p>Для тех, кто хочет добавить объём и&nbsp;изменить форму ягодиц. Идеально если ищете комплексный подход к&nbsp;формированию идеальной попы.</p>
                                            <div className={styles.programs_more}>
                                                <input type="checkbox" id="pt5" name="pt5" />
                                                <label htmlFor="pt5">Как всё устроено 
                                                    <p>
                                                        <ul>
                                                            <li><b>Длительность:</b> 90&nbsp;дней</li>
                                                            <li><b>Инвентарь:</b> бодибар или штанга весом от&nbsp;8&nbsp;кг, гантели разборные до&nbsp;15&nbsp;кг, утяжелители по&nbsp;1,5-3&nbsp;кг, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг</li>
                                                            <li><b>Где заниматься:</b> дома или в&nbsp;зале</li>
                                                            <li><b>программа:</b> 6&nbsp;недель массонабора, 6&nbsp;недель сушки в&nbsp;проблемных зонах</li>
                                                            <li><b>График тренировок:</b> 5&nbsp;раз в&nbsp;неделю</li>
                                                        </ul> 
                                                    </p>
                                                </label>
                                            </div>
                                            <div className={styles.programs_more}>
                                                    <input type="checkbox" id="pnt5" name="pnt5" />
                                                    <label htmlFor="pnt5">Результаты программы 
                                                        <div>
                                                        <ul>
                                                            <li>Увеличите объем и&nbsp;округлость ягодиц</li>
                                                            <li>Улучшите форму и&nbsp;симметрию ягодиц</li>
                                                            <li>Подтянете и&nbsp;укрепите мышцы ягодиц и&nbsp;ног</li>
                                                            <li>Уменьшите проблемные зоны и&nbsp;несовершенства</li>
                                                            <li>Повысьте уверенность в&nbsp;себе и&nbsp;самооценку</li>
                                                        </ul>
                                                        <p>Этот курс предназначен для тех, кто хочет преобразить свои ягодицы и&nbsp;достичь идеальной формы и&nbsp;объема. Благодаря точечным упражнениям и&nbsp;индивидуальному подходу к&nbsp;тренировкам, вы&nbsp;сможешь достичь желаемых результатов и&nbsp;получить попу, о&nbsp;которой мечтали.</p>
                                                        </div>
                                                    </label>
                                                </div>
                                        </div>
                                    </div>
                    </div>
                    {/* <div className={styles.programs_item}>
                                <div className={styles.programs_info}>
                                        <div className={styles.programs_img}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/pr8.png" alt="" />
                                            <div className={styles.programs_img__info}>
                                                <div className={styles.programs_tags}>
                                                    <div className={styles.programs_tag}>Похудеть</div>
                                                    <div className={styles.programs_tag}>Придать телу рельеф</div>
                                                </div>
                                                <div className={styles.programs_img__info_title}>КУРС <span>Жиросжигающий</span></div>
                                                <div className={styles.programs_img__info_price}>9&nbsp;490 <span>₽</span></div>
                                            </div>
                                        </div>
                                        <div className={styles.programs_text}>
                                            <ul>
                                                <li><b>Длительность:</b> 45&nbsp;дней</li>
                                                <li><b>Инвентарь:</b> коврик, фитнес-резинки с&nbsp;сопротивлением 10-22&nbsp;кг, разборные гантели до&nbsp;15&nbsp;кг, утяжелители 1,5-3&nbsp;кг</li>
                                                <li><b>Группы:</b> умеренный, интенсивный и силовой классы</li>
                                                <li><b>График тренировок:</b> 4&nbsp;раза в&nbsp;неделю</li>
                                            </ul>
                                            <div className={styles.programs_more}>
                                                <input type="checkbox" id="pd2" name="pd2" />
                                                <label htmlFor="pd2">Как всё устроено<p>Марафон для тех, кто устал от&nbsp;неэффективных диет и&nbsp;экспериментов над своим телом и&nbsp;мечтаешь избавиться от&nbsp;лишнего жира в&nbsp;проблемных зонах.</p></label>
                                            </div>
                                            <div className={styles.programs_more}>
                                                    <input type="checkbox" id="pnd2" name="pnd2" />
                                                    <label htmlFor="pnd2">Результаты программы 
                                                        <div>
                                                        <ul>
                                                            <li>Снизите процент жира в&nbsp;организме за&nbsp;счет ускорения метаболизма </li>
                                                            <li>Станете выносливее и&nbsp;улучшите физическую форму благодаря интенсивным тренировкам.</li>
                                                            <li>Усилите мышечный корсет и&nbsp;осанку</li>
                                                            <li>Уменьшите объемы талии, бедер и&nbsp;других проблемных зон за&nbsp;счет активного жиросжигания</li>
                                                            <li>Нормализуете питание</li>
                                                        </ul>
                                                        <p>Курс поможет избавиться от&nbsp;лишнего веса, запустить процессы активного жиросжигания и&nbsp;обмена веществ, поможет нормализовать питание и&nbsp;сделать физическую нагрузку нормой.</p>
                                                        </div>
                                                    </label>
                                                </div>
                                        </div>
                                </div>
                    </div> */}
                         
                </div>
                
               {/* <div className={styles.prices_over_btn}><div className={styles.prices_over_btn__price}><span>от&nbsp;748&nbsp;₽/мес.</span></div></div>
                <div className={styles.prices_over_btn}>
                    <div className={styles.prices_over_btn__price}><b>4&nbsp;490&nbsp;₽</b></div>
                    <div className={styles.prices_over_btn__old_price}>17&nbsp;500&nbsp;₽</div>
                    <div className={styles.prices_over_btn__discount}><span>-74%</span></div>
                </div> */}
                <div className={styles.btns}>
                    <div className={styles.promo_btn} onClick={scroll}>
                        <p>Забрать набор<br/> с&nbsp;выгодой 86%</p>
                    </div>
                    {/* <p>-&nbsp;79%</p> */}
                </div>
                {/* <div className={styles.promo_discount}>
                    <div>
                        <span className={styles.left}>Осталось комплектов</span> 
                        <div className={styles.left}>до повышения цены:</div>
                        <span className={styles.count}><span>{window.lps_settings.other.count}/100</span></span>
                    </div>
                </div> */}
                <div className={styles.programs_countdown}>
                    <p>До завершения акции:</p>
                    <div className={styles.programs_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div>
                </div>
                <div className="container">
                    <div className={styles.b2}>
                        <div className={styles.b2_title}>5&nbsp;причин взять полный набор&nbsp;&mdash;<br/> это <span>выгоднее, эффективнее и&nbsp;удобнее!</span></div>
                        <div className={styles.b2_items}>
                                <div className={styles.b2_item}>
                                    <div className={styles.b2_item_tag}>#1</div>
                                    <div className={styles.b2_item_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/b2_1.png" alt="" />
                                    </div>
                                    <div className={styles.b2_item_title}>Программа под любую цель и&nbsp;уровень подготовки</div>
                                    <div className={styles.b2_item_text}>Всегда будет из&nbsp;чего выбрать: программы для сжигания жира, набора мышц, упругой попы или проработки пресса. Уже через 2&ndash;3 недели вы&nbsp;увидите первые результаты!</div>
                                </div>
                                <div className={styles.b2_item}>
                                    <div className={styles.b2_item_tag}>#4</div>
                                    <div className={styles.b2_item_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/b2_2.png" alt="" />
                                    </div>
                                    <div className={styles.b2_item_title}>Найдете любимую программу и&nbsp;стиль тренировок</div>
                                    <div className={styles.b2_item_text}>Попробуйте разные программы и&nbsp;найдёте&nbsp;ту, которая подходит именно вам! Это гарантия, что так вы&nbsp;не&nbsp;забросите тренировки и&nbsp;удержите свою форму.</div>
                                </div>
                                <div className={styles.b2_item}>
                                    <div className={styles.b2_item_tag}>#3</div>
                                    <div className={styles.b2_item_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/b2_3.png" alt="" />
                                    </div>
                                    <div className={styles.b2_item_title}>Разнообразите свои тренировки</div>
                                    <div className={styles.b2_item_text}>Сочетание силовых, кардио и&nbsp;функциональных тренировок&nbsp;&mdash; мышцы не&nbsp;привыкают, а&nbsp;тело быстрее становится стройнее и&nbsp;рельефнее.</div>
                                </div>
                                <div className={styles.b2_item}>
                                    <div className={styles.b2_item_tag}>#2</div>
                                    <div className={styles.b2_item_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/5/pr_add2.png" alt="" />
                                    </div>
                                    <div className={styles.b2_item_title}>Будете пошагово развивать<br/> свое тело</div>
                                    <div className={styles.b2_item_text}>Сможете переходить к&nbsp;более сложным программам. Так прогресс не&nbsp;остановиться и&nbsp;вы&nbsp;постоянно будете прогрессировать.</div>
                                </div>
                                <div className={styles.b2_item}>
                                    <div className={styles.b2_item_tag}>#5</div>
                                    <div className={styles.b2_item_img}>
                                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/b2_5.png" alt="" />
                                    </div>
                                    <div className={styles.b2_item_title}>Это просто выгодно</div>
                                    <div className={styles.b2_item_text}>Покупать сразу 6&nbsp;программ в&nbsp;9&nbsp;раз выгоднее. Если вы&nbsp;хотели заняться собой, то&nbsp;зачем выбирать, если можно сэкономить и&nbsp;взять сразу всё.</div>
                                </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Programs;
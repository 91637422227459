import styles from './prize.module.css';

const Prize = ({scroll}) => {
    return (
        <div className={styles.prize}>
            <div className="container">
                <div className={styles.t2}>Среди всех купивших<br/> <span>разыграем суперпризы</span></div>
                <div className={styles.st2}>Сможете сразу, окупить программу<br/> и&nbsp;добавить себе мотивации!</div>
                <div className={styles.st3}>Розыгрыш пройдет<br className={styles.br2}/> <b>26&nbsp;февраля до&nbsp;18:00 мск</b></div>
                <div className={styles.b2}>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/prize1.png" alt="" />
                        <p>Электрогриль <div>Tefal Optigrill Elite</div></p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/prize2.png" alt="" />
                        <p>Наушники<div>Apple Airpods&nbsp;3 </div></p>
                    </div>
                    <div className={styles.b2_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/prize3.png" alt="" />
                        <p>3 колонки<div>JBL Clip&nbsp;4</div></p>
                    </div>
                </div>

                <div className={styles.btn} onClick={scroll}>Хочу выиграть приз</div>  
            </div>
        </div>
    )
}

export default Prize;
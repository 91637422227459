import styles from './block2.module.css';

const Block2 = ({scroll}) => {
    return (
        <div className={styles.block2}>
            <div className="container">
            {/* <div className={styles.b2}>
                    <div className={styles.b2_info}>
                        <div className={styles.b2_info_title}>Вы&nbsp;точно<br/> будете знать<span> в&nbsp;какой последовательности проходить программы</span></div>
                        <div className={styles.b2_info_text}>Мы&nbsp;создали рекомендации для каждого набора программ, которые помогут понять в&nbsp;какой последовательности проходить тренировки именно вам</div>
                        <div className={styles.b2_info_subtitle}>Рекомендации открываются после покупки вместе с&nbsp;тренировками</div>
                    </div>
                    <div className={styles.b2_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/b2_b2.jpg" alt="" />
                    </div>
            </div> */}
                <div className={styles.img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/3/block2.png" alt="" />
                </div>
                {/* <div className={styles.pt}><b>Откроется до&nbsp;12&nbsp;декабря</b></div> */}
                <div className={styles.pt}>ПРИ ПОКУПКЕ 2 И БОЛЕЕ ПРОГРАММ<br/> СИСТЕМА  ПИТАНИЯ ОТ НУТРИЦИОЛОГА В ПОДАРОК</div>
                <div className={styles.t}>Питайтесь 4&nbsp;раза в&nbsp;день и<span>&nbsp;худейте</span></div>
                <div className={styles.b}>
                    <div className={styles.b_item}>
                        <div className={styles.b_item_num}>1</div>
                        <div className={styles.b_item_text}>
                            <div>Пройдите 7-дневный марафон &laquo;Неделя чистого питания&raquo;</div>
                            <p>Личный нутрициолог Кати, Вероника Усакова разработала программу, которая поможет легко и&nbsp;без стресса адаптировать организм к&nbsp;сбалансированному питанию за&nbsp;7&nbsp;дней. Каждый день вы&nbsp;получаете рекомендации по&nbsp;питанию и&nbsp;быстрые рецепты вкусных блюд. В&nbsp;итоге вы&nbsp;плавно перестраиваете свою систему питания и&nbsp;вырабатываете привычку постоянно питаться правильно.</p>
                        </div>
                    </div>
                    <div className={styles.b_item}>
                        <div className={styles.b_item_num}>2</div>
                        <div className={styles.b_item_text}>
                            <div>Питайтесь по&nbsp;простому правилу<br/> &laquo;здоровой тарелки&raquo; 4&nbsp;раза в&nbsp;день без жёстких ограничений</div>
                            <p>Наша система питания основывается на&nbsp;правиле &laquo;здоровой тарелки&raquo;&nbsp;&mdash; это простой принцип питания, который позволяет питаться 4&nbsp;раза в&nbsp;день без диет, жёстких ограничений, подсчёта КБЖУ и&nbsp;дорогих продуктов. Такая программа помогает легко сбросить лишние килограммы и&nbsp;поддерживать стройное телосложение.</p>
                        </div>
                    </div>
                </div>
                <div className={styles.free}><span>10&nbsp;000&nbsp;₽</span><div><b>бесплатно</b></div></div>
                <div className={styles.free_text}> при покупке 2&nbsp;и&nbsp;более программ</div>
                <div className={styles.btn} onClick={scroll}>ХОЧУ ПРОГРАММУ ПИТАНИЯ</div>

                
            </div>
        </div>
        
    )
}

export default Block2;

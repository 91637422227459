import React, { createContext, useContext } from "react";
import { useMediaQuery } from "react-responsive";

import { useMounted } from "../../hooks";
import { DESKTOP_MIN_WIDTH, DESKTOP_LARGE_MIN_WIDTH } from "../../constants";

export const desktopMediaQuery = { minWidth: DESKTOP_MIN_WIDTH };
export const desktopLargeMediaQuery = { minWidth: DESKTOP_LARGE_MIN_WIDTH };
export const desktopMediumMediaQuery = { minWidth: DESKTOP_MIN_WIDTH, maxWidth: DESKTOP_LARGE_MIN_WIDTH - 1 };
export const mobileMediaQuery = { maxWidth: DESKTOP_MIN_WIDTH - 1 };
export const portraitMediaQuery = { query: "(orientation: portrait)" };
export const landscapeMediaQuery = { query: "(orientation: landscape)" };
export const ultraWideMediaQuery = { query: "(min-aspect-ratio: 7/3)" };

const MediaQuieriesContext = createContext();

export function MediaQuieriesContextPrivider({ width = 1024, height = 768, children }) {
  const aspectRatio = width / height;

  return (
    <MediaQuieriesContext.Provider
      value={{
        type: "screen",
        width: width + "px",
        height: height + "px",
        "aspect-ratio": aspectRatio,
        orientation: aspectRatio > 1 ? "landscape" : "portrait",
      }}
    >
      {children}
    </MediaQuieriesContext.Provider>
  );
}

function useMediaQueryCustom(settings) {
  const context = useContext(MediaQuieriesContext);
  const mounted = useMounted();

  return useMediaQuery(settings, mounted ? undefined : context);
}

export function Desktop({ children }) {
  return useMediaQueryCustom(desktopMediaQuery) ? children : null;
}

export function DesktopLarge({ children }) {
  return useMediaQueryCustom(desktopLargeMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function DesktopMedium({ children }) {
  return useMediaQueryCustom(desktopMediumMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function Mobile({ children }) {
  return useMediaQueryCustom(mobileMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function Portrait({ children }) {
  return useMediaQueryCustom(portraitMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function Landscape({ children }) {
  return useMediaQueryCustom(landscapeMediaQuery) ? <React.Fragment>{children}</React.Fragment> : null;
}

export function MobilePortrait({ children }) {
  return (
    <Mobile>
      <Portrait>{children}</Portrait>
    </Mobile>
  );
}

export function MobileLandscape({ children }) {
  return (
    <Mobile>
      <Landscape>{children}</Landscape>
    </Mobile>
  );
}

export function DesktopOrMobileLandscape({ children }) {
  return (
    <React.Fragment>
      <MobileLandscape>{children}</MobileLandscape>
      <Desktop>{children}</Desktop>
    </React.Fragment>
  );
}

export function DesktopOrMobilePortrait({ children }) {
  return (
    <React.Fragment>
      <MobilePortrait>{children}</MobilePortrait>
      <Desktop>{children}</Desktop>
    </React.Fragment>
  );
}

export function MobileOrDesktopMedium({ children }) {
  return (
    <React.Fragment>
      <Mobile>{children}</Mobile>
      <DesktopMedium>{children}</DesktopMedium>
    </React.Fragment>
  );
}

export function AspectRatioUltraWide({ children }) {
  return useMediaQueryCustom(ultraWideMediaQuery) || typeof window === "undefined" ? children : null;
}

export function AspectRatioNotUltraWide({ children }) {
  return !useMediaQueryCustom(ultraWideMediaQuery) || typeof window === "undefined" ? children : null;
}

import styles from './prizes.module.css';

const Hidden = () => {
    return (
        <div className={styles.prizes_hide}>
            <div className={styles.prizes_hide_top}>
                <div className={styles.t1}>Суперакция к&nbsp;8&nbsp;марта</div>
                <div className={styles.prizes_hide_pretitle}>Извините,<br/> вам недоступен<br/> розыгрыш призов</div>
                {/* <div className={styles.prizes_hide_title}>Извините, вам недоступен<br/> розыгрыш призов</div> */}
            </div>
            <div className={styles.prizes_hide_bottom}>
                <div className={styles.prizes_hide_info}>
                    <div className={styles.prizes_hide_info_number}></div>
                    <div className={styles.prizes_hide_info_text}>Если вы&nbsp;купили комплект всех программ и&nbsp;уже использовали свой шанс, то&nbsp;откройте почту&nbsp;&mdash; там вы&nbsp;найдёте письмо с&nbsp;информацией о&nbsp;подарке.</div>
                </div>
                <div className={styles.prizes_hide_info}>
                    <div className={styles.prizes_hide_info_number}></div>
                    <div className={styles.prizes_hide_info_text}>Если вы&nbsp;ещё не&nbsp;купили фитнес-программы Кати Усмановой, но&nbsp;хотите участвовать в&nbsp;розыгрыше призов, то&nbsp;нажмите кнопку ниже, чтобы участвовать.</div>
                </div>
                <div className={styles.prizes_hide_btns}>
                    {/* <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/eight.png" alt="" /> */}
                    <a href="/l/womday" className={styles.prizes_hide__btn}>Забрать ПРОГРАММЫ</a>
                </div>
                
            </div>
        </div>
    )
}

export default Hidden;


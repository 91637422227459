import styles from './line.module.css';

const Line = ({count, scroll, price}) => {
    return (
        <div className={styles.line}>
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.left}>
                        <div className={styles.count}>Выбрано программ: {count}</div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.price}>{price}&nbsp;₽</div>
                        <div className={styles.btn} onClick={scroll}><p>Перейти к&nbsp;оформлению</p></div>
                    </div>
                </div>
                <div className={styles.btn_hidden} onClick={scroll}><p>Перейти к&nbsp;оформлению</p></div>
            </div>
        </div>
    )
}

export default Line;
import React, { useEffect, useState} from 'react';
import { useInstance } from "@com.marathonium/web2-utils";
import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Faq from './components/Faq';
import Students from './components/Students';
import Start from './components/Start';
import Programs from './components/Programs';
import Second from './components/Second';
import Author from './components/Author';
import Promo from './components/Promo';
import Block2 from './components/Block2';
import Line from './components/Line';
import Gifts from './components/Gifts';
import Students2 from './components/Students2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2025_03_Womday2 = () => {
    const [widget] = useInstance();
    const [activeProgs, setActiveProgs] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
  
    const progs = [
      {id: 1,price: 2190},
      {id: 2,price: 2190},
      {id: 3,price: 3390},
      {id: 4,price: 2190},
      {id: 5,price: 3290},
      {id: 6,price: 2190},
      {id: 7,price: 2790},
      {id: 8,price: 5190},
      {id: 9,price: 3390},
      {id: 10,price: 4390}
    ]

    useEffect(()=>{
      if(activeProgs.length) {
          setIsActive(true)
          let sum = 0
          activeProgs.map((item) => sum += item.price);
          setTotalPrice(sum)
      } else {
          setIsActive(false)
      }
  }, [activeProgs])

    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere2' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start'} );
    };
    
    // let nextMidnight = new Date();
    // nextMidnight.setHours(24, 0, 0, 0);
    let nextMidnight = new Date(Date.UTC(2025, 3, 17, 12, 0, 0));
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    // const getNextTargetTime = () => {
    //   const now = new Date();
    //   const milestone = new Date(Date.UTC(2025, 2, 13, 21, 0, 0));
    //   const asd = new Date(Date.UTC(2025, 2, 13, 21, 0, 0));
    //   const nextAfternoon = new Date(Date.UTC(2025, 2, 14, 12, 0, 0));
    //   if (now < milestone) {
    //       return asd;
    //   }
    //   else
    //   return nextAfternoon;
    // }; 
    // let nextMidnight = getNextTargetTime()
    // const now = new Date();
    // const date = nextMidnight.getTime() - now.getTime();
    // const renderer = ({days, hours, minutes, seconds, completed}) => {
    //     // let dayszero = '';
    //     let hourzero = '';
    //     let minuteszero = '';
    //     let secondszero = '';
    //     // if(days>0) {hours += 24*days}
    //     if(hours<10) {hourzero = '0'}
    //     if(minutes<10) {minuteszero = '0'}
    //     if(seconds<10) {secondszero = '0'}
    //     return (
    //         <div>
    //             {/* <div>{dayszero}{days} <span>:</span></div> */}
    //             <div>{hourzero}{hours} <span>:</span></div>
    //             <div>{minuteszero}{minutes} <span>:</span></div>
    //             <div>{secondszero}{seconds}</div>
    //         </div>
    //     );
    // };

    const renderer2 = ({hours, minutes, seconds, completed}) => {
      let hourzero = '';
      let minuteszero = '';
      let secondszero = '';
      if(hours<10) {hourzero = '0'}
      if(minutes<10) {minuteszero = '0'}
      if(seconds<10) {secondszero = '0'}
      return (
          <div>
              {/* <div>{hourzero}{hours} <span>:</span></div> */}
              <div>{minuteszero}{minutes} <span>:</span></div>
              <div>{secondszero}{seconds}</div>
          </div>
      );
  };

  const chooseFunc = (number) => {
    const elem = document.querySelectorAll('.gt-offer label input');

    // if(elem[1].checked){ elem[1].click(); } 
    if(number == 0) {elem[0].click();} 
    if(number == 1) {elem[1].click();} 
    if(number == 2) {elem[2].click();}
    if(number == 3) {elem[3].click();}
    if(number == 4) {elem[4].click();}
    if(number == 5) {elem[5].click();}
    if(number == 6) {elem[6].click();}
    if(number == 7) {elem[7].click();}
    if(number == 8) {elem[8].click();}
    if(number == 9) {elem[9].click();}
    if(number == 10) {elem[10].click();}
    if(number == 11) {elem[11].click();}
    if(number == 12) {elem[12].click();}
    if(number == 13) {elem[13].click();}
    if(number == 14) {elem[14].click();}
    if(number == 15) {elem[15].click();}
    if(number == 16) {elem[16].click();}
    if(number == 17) {elem[17].click();}
    if(number == 18) {elem[18].click();}
    if(number == 19) {elem[19].click();}
    if(number == 20) {elem[20].click();}
    if(number == 21) {elem[21].click();}
    if(number == 22) {elem[22].click();}
    
    const productIndex = activeProgs.find((el) => el.id === number+1);
    
    if(productIndex) {
        setActiveProgs(activeProgs.filter(obj => obj.id != number+1));
    } else {
        setActiveProgs([...activeProgs, progs[number]]);
    }
 }
    
    return (
      <>
        <DocumentTitle value='Приведите тело в форму к лету — выгода 65% на любой курс или марафон Кати Усмановой'/>
        <DocumentDescription value='Выгода 65% на любой курс или марафон. Доступ до 4 месяцев и 5 бонусных гайдов.' />
        <div className='womens_day_2 '>
              <Promo renderer={renderer} date={date} scroll={scroll} />
              <Second />
              <Programs progs={progs} chooseFunc={chooseFunc} activeProgs={activeProgs} renderer={renderer} date={date} scroll={scroll}/>
              <Block2 scroll={scroll} />
              <Gifts renderer={renderer} date={date} scroll={scroll}/>
              <Students renderer={renderer2} date={date} scroll={scroll} />
              {/* <Students2 /> */}
              <Author />
              <Start progs={progs} chooseFunc={chooseFunc} activeProgs={activeProgs} renderer={renderer} renderer2={renderer2} date={date}/>
              <Faq scroll={scroll}/>
              {isActive && <Line count={activeProgs.length} scroll={scroll2} price={totalPrice} />}
              <Footer version={1} isColor={'#FFFFFF'} />
        </div>
      </>
    );
};
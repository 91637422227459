import styles from './promo.module.css';
import Countdown from 'react-countdown';

const Promo = ({scroll, scroll2, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                        <div className={styles.promo_pretitle}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/p1.svg" alt="" />
                            <p>ПОСЛЕДНИЕ 24 ЧАСА АКЦИИ</p>
                        </div>
                        <div className={styles.promo_title}>Весенняя<br/> перезагрузка <span>-86%</span></div>
                        <div className={styles.promo_subtitle}><b>6&nbsp;самых эффективных программ для дома,</b><br/> чтобы похудеть, убрать целлюлит и&nbsp;создать<br/> идеальную фигуру</div>
                        <div className={styles.promo_mob_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/promo_mob4.png" alt="" className={styles.img_shadow}></img>
                        </div>
                        {/* <div className={styles.bottom_block}>
                            <div className={styles.prize}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/money.png"/>
                                <div className={styles.prize_text}>Самая большая выгода<br/> <span>для первых 100 оплативших</span> </div>
                            </div>
                        </div> */}
                        <div className={styles.buttons}>
                            <div className={styles.btn} onClick={scroll}>Успеть забрать программы</div>
                            <div className={`${styles.btn} ${styles.btn2}`} onClick={scroll2}>Узнать подробнее</div>
                        </div>
                        <div className={styles.countdown_timer}>
                            <p>Спеццена сгорит через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                        {/* <div className={styles.promo_discount}>
                            <p>Осталось комплектов: <span>{window.lps_settings.other.count}/100</span></p>
                            <p>до&nbsp;повышения цены</p>
                        </div>
                        <div className={styles.promo_discount_mob}>
                            <p>Осталось комплектов </p>
                            <p>до&nbsp;повышения цены:</p>
                            <span>{window.lps_settings.other.count}/100</span>
                        </div> */}
            </div>
        </div>
        
    )
}

export default Promo;
import { useEffect, useRef, useState } from 'react';
import styles from './faq.module.css';
import Countdown from 'react-countdown';

const Faq = ({scroll, scroll2, date, renderer}) => {
  const faqs = [
    {
      question: "Подойдут ли мне тренировки?",
      answer: (
        <>
          <p>Тренировки подойдут вам, если у вас нет медицинских противопоказаний к спортивным нагрузкам, но: </p>
            <ul>
              <li>есть лишние кг, от которых нужно избавиться</li>
              <li>есть проблемные зоны</li>
              <li>вы хотите нарастить мышечную массу, создать рельеф тела</li>
            </ul>
        </>
      ),
    },
    {
      question: "В какой последовательности открываются тренировки?",
      answer: (
        <>
        4 марта вы&nbsp;получаете доступ ко&nbsp;всем программам тренировок. 
        Также сразу после покупки вы&nbsp;получите доступ к&nbsp;бонусным гайдам и&nbsp;чату с&nbsp;поддержкой кураторов. 
        Чат с&nbsp;куратором начнет свою работу также 4 марта.
        </>
      ),
    },
    {
      question: "Тренировки проходят в определенное время?",
      answer: (
        <>
        Вы&nbsp;сможете заниматься тогда, когда вам удобно. 
        Вам нужно только выделить 20-40 минут в&nbsp;течение дня или вечера и&nbsp;повторять упражнения за&nbsp;тренером.
        </>
      ),
    },
    {
      question: "Нужно ли покупать абонемент в фитнес-клуб или специальное оборудование?",
      answer: (
        <>
        Программы рассчитаны для тренировок дома. 
        Вы&nbsp;можете обойтись без инвентаря и&nbsp;использовать подручные средства, но&nbsp;для лучшего результата советуем купить минимальное оборудование: фитнес-резинка с&nbsp;сопротивлением medium, наборные или цельные гантели весом 2&nbsp;кг, утяжелители весом 1-2&nbsp;кг.
        </>
      ),
    },
    
    {
      question: "Cмогу ли я заниматься, если живу не в России?",
      answer: (
        <>
        Да. Тренировки доступны в&nbsp;любой точке мира. 
        Вам понадобится смартфон, планшет или компьютер с&nbsp;доступом в&nbsp;интернет. 
        Вы&nbsp;также можете использовать Smart&nbsp;TV.
        </>
      ),
    },
    {
      question:
        "Я оплатила заказ, но мне не пришло письмо с доступом. Что делать?",
      answer: (
        <div>
          <p>
          4 марта мы&nbsp;отправим письмо со&nbsp;ссылкой на&nbsp;вход в&nbsp;личный кабинет. 
          Если вы&nbsp;не&nbsp;нашли письмо даже в&nbsp;папке со&nbsp;спамом, проверьте, 
          правильно&nbsp;ли вы&nbsp;указали адрес электронной почты при оплате или свяжитесь с&nbsp;нашей службой заботы.
          </p>
          <p>
          Обратите внимание, что письмо со&nbsp;ссылкой на&nbsp;личный кабинет приходит в&nbsp;течение 24-х часов в&nbsp;день старта.
          </p>
          <p>
            <a href="mailtosupport@gymteam.ru">support@gymteam.ru</a>
          </p>
          <p>
            <a
              href="https://t.me/GymTeamSupportBot"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg"
                alt=""
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://api.whatsapp.com/send?phone=79958871172"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg"
                alt=""
              />
            </a>
          </p>
        </div>
      ),
    },
    {
      question: "Я оплатила, как получить доступ к тренировкам?",
      answer: (
        <div>
          <p>
            Вы получите доступ к программам комплекта в личном кабинете GymTeam 4 марта. Для доступа к программам
            комплекта вам нужно авторизоваться на сайте или в приложении
            GymTeam.
          </p>
          <p>
            Важно! Авторизация происходит только по номеру телефона, который
            указали при оплате. Обратите внимание, что письмо со ссылкой на
            личный кабинет приходит в течение 24-х часов после оплаты.
          </p>
        </div>
      ),
    },
    {
      question: "Могу я оплатить курс картой иностранного банка?",
      answer: (
        <div>
          <p>
            Да, можете. Всё просто — в блоке с ценой надо нажать «Продолжить» и
            заполнить форму заказа актуальными данными. Потом нажать кнопку
            «Оплатить картой иностранного банка».
          </p>
          <p>
            Откроется платёжная страница, вам надо выбрать удобный способ оплаты
            и нажать кнопку «Оплатить».
          </p>
        </div>
      ),
    },
    {
      question: "Как купить другие программы Екатерины Усмановой?",
      answer: (
        <>
          Купить другие программы вы&nbsp;можете самостоятельно на&nbsp;главной странице <a href='https://gymteam.ru/l/mainpage'>https://gymteam.ru/l/mainpage</a> или напишите нашим менеджерам:
          <p>
            <a href="mailtosupport@gymteam.ru">support@gymteam.ru</a>
          </p>
          <p>
            <a
              href="https://t.me/GymTeamSupportBot"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg"
                alt=""
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://api.whatsapp.com/send?phone=79958871172"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg"
                alt=""
              />
            </a>
          </p>
        </>
      ),
    },
    // {question: 'Как установить мобильное приложение GymTeam?', answer: <div><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></div>},
    // {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <div><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></div>},
  ];

    const [clicked, setClicked] = useState("0")

    const handleToggle = (index) => {
        if (clicked === index) {
            setClicked("0");
        } else {
            setClicked(index);
        }
    }



    return(
        <div className={styles.faq}>
          <div className="container">
            <div className={styles.faq_block}>
              <div className={styles.faq_left}>
                <div className={styles.faq_titles}>
                  <div>
                    {/* <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/faq.png" alt="" /> */}
                  </div>
                  <div className={styles.faq_title}>Отвечаем<br/> на&nbsp;вопросы</div>
                  <div className={styles.btn + ' ' + styles.hide_btn} onClick={scroll}>Забрать с&nbsp;выгодой&nbsp;86%</div>
                  {/* <div className={styles.countdown_text  + ' ' + styles.hide_btn}>Предложение сгорит через:</div> */}
                  {/* <div className={styles.countdown + ' ' + styles.hide_btn}>
                    <div className={styles.countdown_timer}>
                      <Countdown date={Date.now() + 3600000} renderer={renderer} />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className={styles.faq_right}>
                <div className={styles.accordion}>
                  {faqs.map((faq, index) => (
                    <div key={faq.answer} className={clicked == index + 1 ? styles.active : ''}>
                      <p onClick={()=>handleToggle(index+1)} className={styles.question}>{faq.question} </p>
                      <p className={styles.answer}>{faq.answer}</p>
                    </div>
                  ))}
                </div>
                <div className={styles.btn} onClick={scroll}>Забрать с&nbsp;выгодой&nbsp;86%</div>
                {/* <div className={styles.countdown_text}>Предложение сгорит через:</div> */}
                {/* <div className={styles.countdown}>
                  <div className={styles.countdown_timer}>
                    <Countdown date={Date.now() + 3600000} renderer={renderer} />
                  </div>
                </div> */}
                {/* <div className={styles.oferta}>
                 
                  <a href="https://gymteam.kinescopecdn.net/img/l/2025/feb23/ПРИЗОВАЯ_ОФЕРТА_Распродажи_к_23_февраля.pdf" target='_blank'>Конкурсная оферта</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
    );
};

export default Faq;
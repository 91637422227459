import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Author from './components/Author';
import Programs from './components/Programs';
import Students from './components/Students';
import Start from './components/Start';
import Faq from './components/Faq';
import Second from './components/Second';
import Block1 from './components/Block1';
import Prize from './components/Prize';
import Gifts from './components/Gifts';
import Second2 from './components/Second2';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2025_02_February_Sale2 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])
    
    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere2' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    // let nextMidnight = new Date(2024,6,19,0,0,0);
    // let nextMidnight = new Date(Date.UTC(2025,0,30,14,0,0));
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(days>0) {hours += 24*days}
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Мега-распродажа – максимальная выгода на набор фитнес-программ Кати Усмановой и чат с куратором'/>
        <DocumentDescription value='Максимальная выгода 88% на набор из 8 фитнес-программ от Кати Усмановой. Кураторская поддержка и полезные гайды в подарок' />
        <div className='february_sale'>
              <Promo scroll={scroll} scroll2={scroll2} date={date} renderer={renderer} />
              <Second />
              <Programs scroll={scroll} />
              <Block1 />
              <Students date={date} renderer={renderer} />
              <Prize scroll={scroll}/>
              <Gifts scroll={scroll} date={date} renderer={renderer}/>
              <Author />
              <Second2 />
              <Start date={date} renderer={renderer}/>
              <Faq scroll={scroll} date={date} renderer={renderer}/>
              <Footer isColor={'#F9FEFF'} />
        </div>
      </>
    );
};
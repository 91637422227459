import { useEffect, useState } from "react";
import { AuthWidget, checkUserInGroup, addUserToGroup, getUserCountByGroup, ShowForSegment, HideForSegment, checkUserInSegment, getUser } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";

import './styles.css';
import styles from './prizes.module.css';
import Hidden from "./hidden";
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import Already from "./already";
import Footer from "../../components/Footer";


const prizes = [
    {
        id: '41a74499-b52e-4f8b-850c-676248df81ec',
        name: <>Apple iPhone&nbsp;15&nbsp;Pro</>,
        description: <><p>Вы&nbsp;стали одной из&nbsp;немногих, кому достался Apple iPhone 15&nbsp;Pro. Этот смартфон&nbsp;&mdash; мечта многих, но&nbsp;теперь он&nbsp;ваш! Уникальный дизайн, топовая камера и&nbsp;невероятная мощность&nbsp;&mdash; всё в&nbsp;ваших руках.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: '807f2c44-7da8-4360-b183-9eb891aa0207',
        name: <>Apple AirPods 3</>,
        description: <><p>Вы&nbsp;стали обладательницей Apple AirPods 3&nbsp;&mdash; беспроводных наушников, которые подарят вам свободу и&nbsp;качественный звук. Музыка, подкасты, звонки&nbsp;&mdash; теперь всё без лишних проводов и&nbsp;с&nbsp;максимальным комфортом.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: 'ddae21d1-ac7b-4c7a-b034-837dc201803c',
        name: <>Фотоаппарат Instax Square SQ 1</>,
        description: <><p>Вы&nbsp;выиграли Instax Square SQ1&nbsp;&mdash; культовую камеру для моментальных снимков. Запечатлейте лучшие моменты жизни и&nbsp;получайте стильные фотографии мгновенно. Лимит подарков ограничен, и&nbsp;вам улыбнулась удача!</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: 'd9d0ab31-5ab8-4799-99f2-e5110fe4e7da',
        name: <>Беспроводная портативная колонка JBL Clip 4</>,
        description: <><p>Вам досталась портативная колонка JBL Clip&nbsp;4. Компактный, но&nbsp;мощный звук теперь с&nbsp;вами везде: дома, на&nbsp;прогулке или в&nbsp;путешествии. А&nbsp;встроенный карабин позволит закрепить колонку на&nbsp;рюкзаке или велосипеде.</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: '496c0b08-1bbd-4cfd-b101-0cf025da8cdf',
        name: <>Умные весы Futuna Scale 4</>,
        description: <><p>Вы&nbsp;получили Futuna Scale 4&nbsp;&mdash; умные весы, которые помогут вам следить за&nbsp;своим телом. Анализ состава тела, подключение к&nbsp;смартфону и&nbsp;персональные рекомендации&nbsp;&mdash; теперь контроль за&nbsp;формой стал проще.</p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: '84e423ef-26b7-4f92-b171-ff59f5a545eb',
        name: <>Кухонные весы Coffee Scale EK6012</>,
        description: <><p>Вам достались кухонные весы Coffee Scale EK6012&nbsp;&mdash; точность в&nbsp;граммах для идеального приготовления блюд. Готовьте по&nbsp;рецептам, взвешивайте ингредиенты и&nbsp;наслаждайтесь процессом.</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: '698db550-21be-44ec-b727-321a4a330474',
        name: <>Доступ к записи марафона ФМД-марафона</>,
        description: <><p>Вы&nbsp;получили запись ФМД-марафона&nbsp;&mdash; это мощный интенсив для тех, кто хочет улучшить питание и&nbsp;избавиться от&nbsp;лишнего веса. Важная информация, практические советы и&nbsp;простые шаги к&nbsp;обновлению.</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: 'c3cd2c6c-e32c-433b-8553-ae761941598c',
        name: <>Книга рецептов Кати Усмановой</>,
        description: <><p>Теперь у&nbsp;вас есть книга рецептов Кати Усмановой&nbsp;&mdash; сборник проверенных рационов, БЖУ-калькуляции, лайфхаков по&nbsp;готовке и&nbsp;вкусных блюд. Сбалансированное питание стало проще!</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'b9478506-33ee-4e06-afa2-9a922bc1dee4',
        name: <>Марафон Детокс</>,
        description: <><p>Вы&nbsp;получили доступ к&nbsp;марафону &laquo;Детокс&raquo;. Это возможность пересмотреть своё питание, узнать, какие продукты мешают вашему самочувствию, и&nbsp;почувствовать лёгкость уже в&nbsp;первые дни.</p></>,
        limit: window.lps_settings.limits.prize9,
    },
]

const prizes2 = [
    {
        id: '41a74499-b52e-4f8b-850c-676248df81ec',
        name: <>Apple iPhone&nbsp;15&nbsp;Pro</>,
        description: <><p>Вы&nbsp;стали одной из&nbsp;немногих, кому достался Apple iPhone 15&nbsp;Pro. Этот смартфон&nbsp;&mdash; мечта многих, но&nbsp;теперь он&nbsp;ваш! Уникальный дизайн, топовая камера и&nbsp;невероятная мощность&nbsp;&mdash; всё в&nbsp;ваших руках.</p></>,
        limit: window.lps_settings.limits.prize1,
    },
    {
        id: '807f2c44-7da8-4360-b183-9eb891aa0207',
        name: <>Apple AirPods 3</>,
        description: <><p>Вы&nbsp;стали обладательницей Apple AirPods 3&nbsp;&mdash; беспроводных наушников, которые подарят вам свободу и&nbsp;качественный звук. Музыка, подкасты, звонки&nbsp;&mdash; теперь всё без лишних проводов и&nbsp;с&nbsp;максимальным комфортом.</p></>,
        limit: window.lps_settings.limits.prize2,
    },
    {
        id: 'ddae21d1-ac7b-4c7a-b034-837dc201803c',
        name: <>Фотоаппарат Instax Square SQ 1</>,
        description: <><p>Вы&nbsp;выиграли Instax Square SQ1&nbsp;&mdash; культовую камеру для моментальных снимков. Запечатлейте лучшие моменты жизни и&nbsp;получайте стильные фотографии мгновенно. Лимит подарков ограничен, и&nbsp;вам улыбнулась удача!</p></>,
        limit: window.lps_settings.limits.prize3,
    },
    {
        id: 'd9d0ab31-5ab8-4799-99f2-e5110fe4e7da',
        name: <>Беспроводная портативная колонка JBL Clip 4</>,
        description: <><p>Вам досталась портативная колонка JBL Clip&nbsp;4. Компактный, но&nbsp;мощный звук теперь с&nbsp;вами везде: дома, на&nbsp;прогулке или в&nbsp;путешествии. А&nbsp;встроенный карабин позволит закрепить колонку на&nbsp;рюкзаке или велосипеде.</p></>,
        limit: window.lps_settings.limits.prize4,
    },
    {
        id: '496c0b08-1bbd-4cfd-b101-0cf025da8cdf',
        name: <>Умные весы Futuna Scale 4</>,
        description: <><p>Вы&nbsp;получили Futuna Scale 4&nbsp;&mdash; умные весы, которые помогут вам следить за&nbsp;своим телом. Анализ состава тела, подключение к&nbsp;смартфону и&nbsp;персональные рекомендации&nbsp;&mdash; теперь контроль за&nbsp;формой стал проще.</p></>,
        limit: window.lps_settings.limits.prize5,
    },
    {
        id: '84e423ef-26b7-4f92-b171-ff59f5a545eb',
        name: <>Кухонные весы Coffee Scale EK6012</>,
        description: <><p>Вам достались кухонные весы Coffee Scale EK6012&nbsp;&mdash; точность в&nbsp;граммах для идеального приготовления блюд. Готовьте по&nbsp;рецептам, взвешивайте ингредиенты и&nbsp;наслаждайтесь процессом.</p></>,
        limit: window.lps_settings.limits.prize6,
    },
    {
        id: '698db550-21be-44ec-b727-321a4a330474',
        name: <>Доступ к записи марафона ФМД-марафона</>,
        description: <><p>Вы&nbsp;получили запись ФМД-марафона&nbsp;&mdash; это мощный интенсив для тех, кто хочет улучшить питание и&nbsp;избавиться от&nbsp;лишнего веса. Важная информация, практические советы и&nbsp;простые шаги к&nbsp;обновлению.</p></>,
        limit: window.lps_settings.limits.prize7,
    },
    {
        id: 'c3cd2c6c-e32c-433b-8553-ae761941598c',
        name: <>Книга рецептов Кати Усмановой</>,
        description: <><p>Теперь у&nbsp;вас есть книга рецептов Кати Усмановой&nbsp;&mdash; сборник проверенных рационов, БЖУ-калькуляции, лайфхаков по&nbsp;готовке и&nbsp;вкусных блюд. Сбалансированное питание стало проще!</p></>,
        limit: window.lps_settings.limits.prize8,
    },
    {
        id: 'b9478506-33ee-4e06-afa2-9a922bc1dee4',
        name: <>Марафон Детокс</>,
        description: <><p>Вы&nbsp;получили доступ к&nbsp;марафону &laquo;Детокс&raquo;. Это возможность пересмотреть своё питание, узнать, какие продукты мешают вашему самочувствию, и&nbsp;почувствовать лёгкость уже в&nbsp;первые дни.</p></>,
        limit: window.lps_settings.limits.prize9,
    },
]

export const L2025_03_Womday_box = () => {
    const [modalActive, setModalActive] = useState(false)
    const [activePrize, setActivePrize] = useState({})
    const [isSelected, setIsSelected] = useState(false)
    const [alreadyShow, setAlreadyShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isRusPhone, setIsRusPhone] = useState(false)

    function randomNumber(min, max) { 
        return Math.round(Math.random() * (max - min) + min);
    }

    useEffect(()=>{
        getUser().then((res) => {
            if(res.phone.substr(0,2) == '+7' && res.phone.length == 12) {
                setIsRusPhone(true)
            }
        })
    }, [])

    useEffect(()=> {
        {prizes.map((i, idx)=>{
            checkUserInGroup(i.id).then(res => {
                // console.log(res == null)
                if(res !== null) {
                    // console.log(res)
                    setAlreadyShow(true)
                }
            })
            if(idx + 1 == prizes.length) {
                setTimeout(()=>{
                    setIsLoading(false)
                }, 2000)
            }
        })}
        
    }, [])
    
    function givePrize() {
        setIsSelected(true)
        
        checkUserInSegment(window.lps_settings.segments.segment_womdaybox).then(res => {
        if (!res) {
            return false;
        }

            if(isRusPhone) {
                Promise.all(prizes.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                    const availablePrizes = prizes.filter((i, idx) => {
                        return values[idx] < i.limit;
                    });
                    
                    if (availablePrizes.length === 0) {
                        return false;
                    }
                    
                    const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                    const prize = availablePrizes[randomPriceIdx];
                    
                    addUserToGroup(prize.id).then((res)=>{
                        setModalActive(true)
                        setActivePrize(prize) 
                    }) 
          
                    return prize;
                  })
                //   .then((prize) => console.log(prize));
            } else {
                Promise.all(prizes2.map((i,idx) => getUserCountByGroup(i.id))).then((values) => {
                    const availablePrizes = prizes2.filter((i, idx) => {
                        return values[idx] < i.limit;
                    });
                    
                    if (availablePrizes.length === 0) {
                        return false;
                    }
                    
                    const randomPriceIdx = randomNumber(0, availablePrizes.length - 1);
                    const prize = availablePrizes[randomPriceIdx];
                    
                    addUserToGroup(prize.id).then((res)=>{
                        setModalActive(true)
                        setActivePrize(prize) 
                    }) 
          
                    return prize;
                  })
                //   .then((prize) => console.log(prize));
            }
      });
    }

    function Boxes() {
        return (
            <>
                {!modalActive ?
                                <>
                                    <div className={styles.prizes_hide_top}>
                                        <div className={styles.t1}>Суперакция к&nbsp;8&nbsp;марта</div>
                                        <div className={styles.title2}>ПРИЗОВЫЕ БОКСЫ</div>
                                        <div className={styles.title3}>Вас ждёт гарантированный приз! Скорее<br/> выбирайте один из&nbsp;боксов и&nbsp;узнайте, что<br/> достанется именно вам.</div>
                                    </div>
                                    <div className={`${styles.prizes_block} ${isSelected && styles.prizes_block__active}`}>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                        <div className={`${styles.prizes_item} ${isSelected && styles.prizes_item__active}`} onClick={givePrize}>
                                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/prize.png" alt="" />
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className={styles.win}>
                                        {/* <div className={styles.win_img}><img src="https://gymteam.kinescopecdn.net/img/l/2024/womdaybox/bant.png" alt="" /></div> */}
                                        <div className={styles.win_name}>
                                            {activePrize.name}
                                        </div>
                                        <div className={styles.win_desc}>
                                            <p style={{marginBottom: 10}}><b>Поздравляем!</b></p>
                                            {activePrize.description}
                                        </div>
                                        <div className={styles.win_more}>Подробная информация о&nbsp;получении подарка у&nbsp;вас&nbsp;на&nbsp;почте.</div>
                                    </div>
                                    </>
                            }
            </>
        )
    }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <HideForSegment id={window.lps_settings.segments.segment_womdaybox}>
                    <Hidden/>
                </HideForSegment>

                <ShowForSegment id={window.lps_settings.segments.segment_womdaybox}>
                
                {isLoading
                ? <div className={styles.loader_block}><div className={styles.loader}><div></div><div></div><div></div><div></div></div></div>
                : <>
                    {alreadyShow
                        ? <Already />
                        : <Boxes/>
                    }
                  </>
                }
                                
                </ShowForSegment>
            
            </Authorized>
            <Anonymous>
                <div className={styles.prizes_ghost_title}>Чтобы получить доступ к&nbsp;боксам<br/> нужно авторизоваться</div>
                <div className="velvet_prizes_login">
                    <AuthWidget />
                </div>
            </Anonymous>
          </>
        );
      }

    return (
        <div className={styles.cursor}>
        <DocumentTitle value='Призовые боксы'/>
        <DocumentDescription value='' />
        <div className={styles.prizes}>
            <div className={styles.prizes_main}>
               <LoginForm />
            </div>
            
        </div>
        <Footer />
        </div>
    );
};
import React, { useEffect } from "react";
import { URLSearchParamsCatcher } from "@com.marathonium/web2-utils";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { theme } from "./theme";
import "./style.css";

import { L2022_11_Pregnancy } from "./2022-11-pregnancy";
import { L2022_11_Afterpregnancy } from "./2022-11-afterpregnancy";
import { L2022_11_Marafon_str1 } from "./2022-11-marafon-str1";
import { L2022_12_Marafon_abs } from "./2022-11-slimstomach";
import { L2022_12_Marafon_fatburning } from "./2022-12-fatburning";
import { L2022_12_Marafon_popa2 } from "./2022-12-marafonpopa2";
import { L2022_12_Marafon_popa1 } from "./2022-12-marafonpopa1";
import { L2022_12_Marafon_removefat } from "./2022-12-removefat";
import { L2022_12_Marafon_anticellulite } from "./2022-11-anticellulite";
import { L2023_06_Rasprozarka10 } from "./2023/06-rasprozarka10";
import { L2022_12_Coursepop2 } from "./2022-12-coursepop2";
import { L2022_12_Coursepop3 } from "./2022-12-coursepop3";
import { L2022_12_Nutrition } from "./2022-12-nutrition";
import { L2023_06_Calculator } from "./2023/06-kbzucalc";
import { TestWidget } from "./testwidget";
import { L2024_07_Thanks } from "./2024/06-order/thanks";
import { L2024_08_Vitrina } from "./2024/08-vitrina";
import { L2024_08_Allprogramms } from "./2024/08-allprogramms";
import { L2024_06_Order } from "./2024/06-order";
import { MyRedirect } from "./2023/gtv/components/redirec";
import { L2023_09_Prolong } from "./2023/08-velvet3/index3";
import { Legal_public } from "./components/oferts/Public";
import { Legal_confidentiality } from "./components/oferts/Confidentiality";
import { Guest } from "./2023/Guest";

import { L2023_05_Ofertacontest } from "./2023/05-ofertacontest"; // ?
import { L2023_01_Gymteamsale } from "./2023/01-gymteamsale"; // ?
import { L2023_05_Referral } from "./2023/05-referral"; // ?
import { Reftest } from "./2023/reftest"; // ?
import { Refregpage } from "./2023/reftest/refreg"; // ?
import { L2023_06_Ofertacontestgym } from "./2023/06-ofertacontestgym"; // ?
import { TransferPage } from "./2022-12-transfer"; // ?
import { L2023_06_Universalpop } from "./2023/06-universalpop"; // ?
import { L2023_06_Stretchweek } from "./2023/06-stretchweek"; // ?
import { L2023_08_Salemanager } from "./2023/08-velvet3/index2"; // ?
import { L2024_01_Corporate } from "./2024/01-corporate"; // ?
import { L2023_09_Fatlost } from "./2023/09-fatburning"; // ?
import { L2023_09_Fatlost2 } from "./2023/09-fatburning/second"; // ?
import { L2023_09_Fatlost3 } from "./2023/09-fatburning/third"; // ?
import { L2023_09_Fatlost4 } from "./2023/09-fatburning/fourth"; // ?
import { L2023_09_Fatlost5 } from "./2023/09-fatburning/fifth"; // ?
import { L2023_09_Fatlost6 } from "./2023/09-fatburning/six"; // ?
import { L2024_03_8march } from "./2024/03-8march"; // ?
import { L2024_04_Oferta_metod } from "./2023/06-ofertacontestgym/index2"; // ?
import { L2024_08_Oferta_metod_p } from "./2024/08-um2/oferta_p"; // ?
import { L2024_08_Oferta_metod_c } from "./2024/08-um2/oferta_c"; // ?
import { L2024_07_Um2 } from "./2024/08-um2";
import { L2024_10_BirthdayUsm } from "./2024/10-birthdayusm";
import { L2024_10_Vitrinathanks } from "./2024/08-vitrina/thanks";
import { L2024_10_prebftest } from "./2024/10-prebf/indextest";
import { L2024_11_bf3 } from "./2024/11-bf3";
import { L2024_12_checkform } from "./2024/12-checkform";
import { L2024_12_Missbikini } from "./2024/12-missbikini";
import { L2024_07_Mu } from "./2024/07-mu";
import { L2024_12_Mu } from "./2024/12-mu";
import { L2024_12_Mu_Presale_2 } from "./2024/12-mu-presale-2";
import { L2024_12_online2024 } from "./2024/12-online2024";
import { L2024_12_SpecMu_3 } from "./2024/12-specmu-3";
import { L2025_02_Coursegym_spec } from "./2025/02-coursegym_february_spec";
import { Legal_oferta } from "./components/oferts/Oferta";
import { L2025_02_February_Sale2 } from "./2025/02-february-23-sale2";
import { L2025_02_SpringSale } from "./2025/02-springsale2";
import { L2025_03_Womday } from "./2025/03-womday";
import { L2025_03_Womday_box } from "./2025/03-wdbox";
import { L2025_03_Lks } from "./2025/03-lks";
import { L2025_03_Womday2 } from "./2025/03-womday2";
import { L2025_03_Coursegym } from "./2025/03-coursegym_march";

function App() {
	// const redirectUrls = ["/action_summer", "/bikinifest24", "/birthday", "/blackfriday2023", "/blackfriday2024_2", "/blackfriday2024", "/blossom", "/mom", "/missutcontestresultsaugust2023", "/misshot", "/miss2024", "/metod2024", "/metod2", "/metod_base", "/method3", "/marafon", "/january_sale", "/lessons", "/holidays", "/gymmarch", "/gymjune", "/gymfest", "/gym", "/goodbyeoversize", "/fitnesvesna", "/fitmission", "/fit_summer", "/fitmiss", "/coursegym1", "/decembergym", "/culture", "/coursegym_october", "/change", "/woweffect", "/womday", "/weekends", "/vforme", "/velvet", "/summerstr", "/springawake", "/septembersale", "/sale_coursegym", "/restart", "/reg_metod2", "/rasprozharka2024", "/rasprozarka", "/prostokosmos", "/prodli_leto24", "/popculture", "/perezagruzka", "/onlyforlove", "/newyear2024", "/new", "/new_me", "/metodpay", "/metod_start", "/um_special", "/metod_base", "/lks_december", "/new_me", "/birthday_fitness", "/coursegym_october", "/sale_restart", "/blackfriday2024", "/specoffer2_blackfriday2024", "/specoffer_blackfriday2024", "/method3", "/newyear2025", "/prodli_leto24", "/prodli_leto2", "/presale2_metod2", "/specoffer2_metod2",
	// ]

	useEffect(() => {
		const mode = localStorage.getItem("chakra-ui-color-mode");
		if (mode !== "light") {
			localStorage.setItem("chakra-ui-color-mode", "light");
			window.location.reload(false);
		}
	}, []);

	return (
		<ChakraProvider resetCSS theme={theme}>
			<URLSearchParamsCatcher />
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Routes>
					<Route path="/afterpregnancy" element={<L2022_11_Afterpregnancy />} />
					<Route path="/pregnancy" element={<L2022_11_Pregnancy />} />
					<Route path="/marafonstr1" element={<L2022_11_Marafon_str1 />} />
					<Route path="/marafonabs" element={<L2022_12_Marafon_abs />} />
					<Route path="/fatburning" element={<L2022_12_Marafon_fatburning />} />
					<Route path="/marafonpopa1" element={<L2022_12_Marafon_popa1 />} />
					<Route path="/marafonpopa2" element={<L2022_12_Marafon_popa2 />} />
					<Route path="/removefat" element={<L2022_12_Marafon_removefat />} />
					<Route path="/anticellulite" element={<L2022_12_Marafon_anticellulite />} />
					<Route path="/coursepop2" element={<L2022_12_Coursepop2 />} />
					<Route path="/coursepop3" element={<L2022_12_Coursepop3 />} />
					<Route path="/nutrition" element={<L2022_12_Nutrition />} />
					<Route path="/mainpage" element={<L2024_08_Vitrina />} />
					<Route path="/testwidget" element={<TestWidget />} />
					<Route path="/transfer" element={<TransferPage />} />
					<Route path="/gtv" element={<MyRedirect url={window.lps_settings.regirect + '/start'}/>} />
					<Route path="/gtv2" element={<MyRedirect url={window.lps_settings.regirect + '/start'}/>} />
					<Route path="/price" element={<MyRedirect url={window.lps_settings.regirect + '/price'}/>} />
					<Route path="/gymteamsale" element={<L2023_01_Gymteamsale />} />
					<Route path="/reftest" element={<Reftest />} />
					<Route path="/refregpage" element={<Refregpage />} />
					<Route path="/ofertacontest" element={<L2023_05_Ofertacontest />} />
					<Route path="/ofertacontestgym" element={<L2023_06_Ofertacontestgym />} />
					<Route path="/coursegym" element={<L2023_06_Rasprozarka10 />} />
					<Route path="/referral" element={<L2023_05_Referral />} />
					<Route path="/calculator" element={<L2023_06_Calculator />} />
					<Route path="/universalpop" element={<L2023_06_Universalpop />} />
					<Route path="/stretching" element={<L2023_06_Stretchweek />} />
					<Route path="/legal/public" element={<Legal_public />} />
					<Route path="/legal/confidentiality" element={<Legal_confidentiality />} />
					<Route path="/legal/oferta" element={<Legal_oferta />} />
					<Route path="/salemanager" element={<L2023_08_Salemanager />} />
					<Route path="/prolong" element={<L2023_09_Prolong />} />
					<Route path="/fatlost" element={<L2023_09_Fatlost />} />
					<Route path="/fatlost/1year" element={<L2023_09_Fatlost2 />} />
					<Route path="/fatlost/6month" element={<L2023_09_Fatlost3 />} />
					<Route path="/fatlost/3month" element={<L2023_09_Fatlost4 />} />
					<Route path="/fatlost/1month" element={<L2023_09_Fatlost5 />} />
					<Route path="/fatlost/1year15" element={<L2023_09_Fatlost6 />} />
					<Route path="/anketa" element={<Guest />} />
					<Route path="/corporate" element={<L2024_01_Corporate />} />
					<Route path="/8march" element={<L2024_03_8march />} />
					
					<Route path="/oferta_metod" element={<L2024_04_Oferta_metod />} />
					
					<Route path="/order/:id" element={<L2024_06_Order />} />
					<Route path="/order/:id/:code" element={<L2024_06_Order />} />
					<Route path="/thanks" element={<L2024_07_Thanks />} />

					<Route path="/do-it" element={<MyRedirect url={window.lps_settings.regirect + '/do-it'}/>} />
					<Route path="/do-it-reg" element={<MyRedirect url={window.lps_settings.regirect + '/do-it-reg'}/>} />
					<Route path="/price-new" element={<MyRedirect url={window.lps_settings.regirect + '/price-new'}/>} />
					<Route path="/quiz-test" element={<MyRedirect url={window.lps_settings.regirect + '/quiz-test'}/>} />


					{/* {redirectUrls.map((url, index) => (
						<Route key={index} path={url} element={<Navigate to="/mainpage" replace={true} />} />
					))} */}
					
					
					
					<Route path="/allprogramms" element={<L2024_08_Allprogramms />} />

					<Route path="/oferta_metod_c" element={<L2024_08_Oferta_metod_c />} />
					<Route path="/oferta_metod_p" element={<L2024_08_Oferta_metod_p />} />
					<Route
						path="/open_training"
						element={<MyRedirect url="https://usmanovateam.ru/open_training" />}
					/>
					<Route
						path="/webinar_show"
						element={
							<MyRedirect url="https://usmanovateam.ru/open_training/web" />
						}
					/>
					<Route
						path="/open_train_dec"
						element={
							<MyRedirect url="https://usmanovateam.ru/open_train_dec" />
						}
					/>
					<Route
						path="/lks_february"
						element={
							<MyRedirect url="https://usmanovalove.ru/lks_february" />
						}
					/>
					
					<Route path="/reg_metod2" element={<L2024_07_Mu />} />

					<Route path="/missbikini" element={<L2024_12_Missbikini />} />
					
					<Route path="/blackfriday2024_2" element={<L2024_11_bf3 />} />
					
					<Route path="/checkform" element={<L2024_12_checkform />} />
					<Route path="/checkform2" element={<L2024_12_checkform />} />
					<Route path="/test" element={<L2024_07_Um2 />} />
					
					<Route path="/presale_method3" element={<L2024_12_Mu />} />
					<Route path="/presale2_method3" element={<L2024_12_Mu_Presale_2 />} />
					<Route path="/online2024" element={<L2024_12_online2024 />} />

					<Route path="/specoffer_coursegym" element={<L2025_02_Coursegym_spec />} />

					<Route path="/specoffer_method3" element={<L2024_12_SpecMu_3 />} />
					
					<Route path="/spring_sale2" element={<L2025_02_February_Sale2 />} />

					<Route path="/spring_sale_test" element={<L2025_02_SpringSale />} />
					
					
					<Route path="/womens_day_test" element={<L2025_03_Womday2 />} />
					<Route path="/wd_boxes" element={<L2025_03_Womday_box />} />

					

					<Route path="/lks_specoffer" element={<L2025_03_Lks />} />					
					
					<Route path="/testbutton" element={<L2024_10_prebftest />} />
					<Route path="/test_forma" element={<L2024_10_BirthdayUsm version={1} />} />
					<Route path="/test_auth" element={<L2024_10_BirthdayUsm version={2} />} />
					
					<Route path="/mainpage/thx_mail" element={<L2024_10_Vitrinathanks />} />
					
					<Route path="/coursegym_march" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/womens_day" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/spring_sale" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/mom_february" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/january_sale" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/coursegym_february" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/action_summer" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/bikinifest24" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/birthday" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/blackfriday2023" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/blackfriday2024_2" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/blackfriday2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/blossom" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/mom" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/missutcontestresultsaugust2023" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/misshot" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/miss2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/metod2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/metod2" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/metod_base" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/method3" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/marafon" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/january_sale" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/lessons" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/holidays" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/gymmarch" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/gymjune" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/gymfest" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/gym" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/goodbyeoversize" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/fitnesvesna" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/fitmission" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/fit_summer" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/fitmiss" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/coursegym1" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/decembergym" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/culture" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/coursegym_october" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/change" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/woweffect" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/womday" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/weekends" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/vforme" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/velvet" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/summerstr" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/springawake" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/septembersale" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/sale_coursegym" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/restart" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/reg_metod2" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/rasprozharka2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/rasprozarka" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/prostokosmos" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/prodli_leto24" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/popculture" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/perezagruzka" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/onlyforlove" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/newyear2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/new" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/new_me" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/metodpay" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/metod_start" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/um_special" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/metod_base" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/lks_december" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/new_me" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/birthday_fitness" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/coursegym_october" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/sale_restart" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/blackfriday2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/specoffer2_blackfriday2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/specoffer_blackfriday2024" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/method3" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/newyear2025" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/prodli_leto24" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/prodli_leto2" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/presale2_metod2" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/specoffer2_metod2" element={<Navigate to="/mainpage" replace={true} />} />
					<Route path="/bf_boxes" element={<Navigate to="/mainpage" replace={true} />} />


				</Routes>
			</BrowserRouter>
		</ChakraProvider>
	);
}

export default App;

import Countdown from "react-countdown";
import { SalesWidget } from "@com.marathonium/web2-ui";
import styles from "./start.module.css";
import { useInstance } from "@com.marathonium/web2-utils";
import { useState } from "react";

const Start = ({ renderer, date }) => {
	const [widget] = useInstance();
	// const [form, setForm] = useState(false);
	
	return (
		<div className={styles.oh}>
			<div className={"scrollhere " + styles.start}>
				<div className="container">
					<div className={styles.block}>
					<div className={styles.inf}>ПОСЛЕДНИЕ 24 ЧАСА АКЦИИ</div>
						{/* <div className={styles.pretitle}>
						НАБОР ПРОГРАММ <br className={styles.br1}/>ПО&nbsp;ЦЕНЕ ОДНОГО МАРАФОНА!
						</div> */}
						<div className={styles.title}>Успейте забрать<br/> 6&nbsp;фитнес-программ <span>с&nbsp;выгодой&nbsp;86%</span></div>
						<div className={styles.subtitle}>6&nbsp;программ тренировок для дома, чтобы <b>построить изящную,<br/> гармоничную и&nbsp;спортивную фигуру</b></div>
						<div className={styles.prices}>7&nbsp;490&nbsp;<i>₽</i> <em>55&nbsp;940&nbsp;<i>₽</i></em>
							<span>(от&nbsp;1&nbsp;248&nbsp;₽/мес.)</span>
						</div>
						<div className={styles.remains_n}>
							<div className={styles.timer}>
								<p>Предложение сгорит<br className={styles.br1}/> через:</p>
								<Countdown date={Date.now() + date} renderer={renderer} />
							</div>
							{/* <div className={styles.remains_n_place}>Осталось комплектов: <br className={styles.br1}/>
								<span>{window.lps_settings.other.count}/100</span>
							</div> */}
						</div>
						{/* <div className={styles.remains}>
							<div className={styles.remains_text}>Спецпредложение <b>с&nbsp;выгодой&nbsp;90%</b> сгорит через:</div>
							<div className={styles.remains_number}>
								{window.lps_settings.other.count}
							</div>
							<div className={styles.timer2}>
								<Countdown date={Date.now() + date} renderer={renderer} />
							</div>
						</div> */}
						{/* <div className={styles.pretitle}>Такое предложение бывает <b>только раз в&nbsp;году!</b></div> */}
						<div className={styles.dts}>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/clock.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Доступ&nbsp;&mdash; 6&nbsp;месяцев
								</div>
							</div>
							
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/calendar.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Старт тренировок&nbsp;&mdash; 4&nbsp;марта
								</div>
							</div>
							<div className={styles.dts_item}>
								<div className={styles.dts_item_img}>
									<img
										src="https://gymteam.kinescopecdn.net/img/l/2025/feb23/2/chat.svg"
										alt=""
									/>
								</div>
								<div className={styles.dts_item_text}>
									Чат с&nbsp;куратором&nbsp;&mdash; <span>С&nbsp;3&nbsp;ПО 31&nbsp;МАРТА</span>
								</div>
							</div>
						</div>
						{/* <div className={styles.info_all}>
							<div className={styles.info_all_name}>
								Набор "Черная пятница 2024"
							</div>
							<div className={styles.info_all_prices}>
								19&nbsp;990&nbsp;₽ <em>199&nbsp;480&nbsp;₽</em>{" "}
								<span>от&nbsp;1 658&nbsp;₽/мес.</span>
							</div>
						</div> */}
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>
							Набор&nbsp;из&nbsp;6&nbsp;программ Кати Усмановой
							</div>
							{/* <div className={styles.info_all_prices}>
								<em>89&nbsp;990&nbsp;₽</em>{" "}9&nbsp;990&nbsp;₽
								<span>от&nbsp;1 658&nbsp;₽/мес.</span>
							</div> */}
							<div className={styles.info_all_prices}>
								{/* <em>89&nbsp;480&nbsp;₽</em> */}
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Стройность 1.0&raquo;
								</p>
								<span>6&nbsp;490&nbsp;₽</span>
							</div>
							{/* <div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Упругой попы 1.0&raquo;
								</p>
								<span>6&nbsp;490&nbsp;₽</span>
							</div> */}
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Упругой попы 2.0&raquo;
								</p>
								<span>9&nbsp;990&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Плоского живота&raquo;
								</p>
								<span>6&nbsp;490&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Антицеллюлитный&raquo;
								</p>
								<span>9&nbsp;990&nbsp;₽</span>
							</div>
							<div className={styles.info_item}>
								<p>
									<b>Марафон</b> &laquo;Убираем лишний жир&raquo;
								</p>
								<span>9&nbsp;990&nbsp;₽</span>
							</div>
							{/* <div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Восстановление после родов&raquo;
								</p>
								<span>7&nbsp;900&nbsp;₽</span>
							</div> */}
							{/* <div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Питание&raquo;
								</p>
								<span>10&nbsp;000&nbsp;₽</span>
							</div> */}
							{/* <div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Идеальной попы 2.0&raquo;
								</p>
								<span>6&nbsp;000&nbsp;₽</span>
							</div> */}
							<div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Идеальной попы 3.0&raquo;
								</p>
								<span>14&nbsp;990&nbsp;₽</span>
							</div>
							{/* <div className={styles.info_item}>
								<p>
									<b>Курс</b> &laquo;Жиросжигающий&raquo;
								</p>
								<span>9&nbsp;490&nbsp;₽</span>
							</div> */}
							{/* <div className={styles.info_item}>
								<p>
									<b>Универсальные тренировки на&nbsp;ягодицы</b>
								</p>
								<span>4&nbsp;790&nbsp;₽</span>
							</div> */}
							{/* <div className={styles.info_item}>
								<p>
									<b>Курс для зала. Цикл&nbsp;1</b>
								</p>
								<span>3&nbsp;490&nbsp;₽</span>
							</div> */}
						</div>

						<div className={styles.info_all}>
							<div className={styles.info_all_name}>Подарки всем купившим</div>
							<div className={styles.info_all_prices}>
								Бесплатно
							</div>
						</div>
						<div className={styles.info}>
							<div className={styles.info_item}>
								<p>
									<b>+ чат с&nbsp;поддержкой кураторов на&nbsp;1&nbsp;месяц</b>
								</p>
								{/* <span>7&nbsp;990&nbsp;₽</span> */}
							</div>
						</div>
						{/* <div className={styles.info_all}>
							<div className={styles.info_all_name}>Участие в&nbsp;розыгрыше поездки в&nbsp;Дубай</div>
						</div>
						<div className={styles.info_all}>
							<div className={styles.info_all_name}>Участие в&nbsp;розыгрыше Iphone 16&nbsp;pro и&nbsp;других призов</div>
						</div> */}

						{/* <div className={styles.timer}>
							<Countdown date={Date.now() + 3600000} renderer={renderer} />
						</div> */}
						
							<div className="spring_sale_start">
								<SalesWidget
									hook={widget}
									id={window.lps_settings.forms.feb23_7_id}
									buttonText="Успеть оформить заказ"
								/>
							</div>
							{/* <div className={styles.remains_n}> */}
							{/* <div className={styles.timer}>
								<p>Предложение сгорит<br className={styles.br1}/> через:</p>
								<Countdown date={Date.now() + date} renderer={renderer} />
							</div> */}
							{/* <div className={styles.remains_n_place}>Осталось мест с&nbsp;выгодой: <div className={styles.count_text}><span>{window.lps_settings.other.count}</span>/100</div></div> */}
							{/* </div> */}

						<div className={styles.pay}>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank1.png"
									alt=""
								/>
								<p>Рассрочка без процентов от&nbsp; 1&nbsp;248&nbsp;₽ в&nbsp;месяц</p>
								<span>
									Вы&nbsp;можете оформить заказ в&nbsp;рассрочку:
									на&nbsp;6&nbsp;месяцев без процентов
									<br /> и&nbsp;предварительного взноса. Только для
									граждан&nbsp;РФ.
								</span>
							</div>
							<div className={styles.pay_item}>
								<img
									src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/bank2.png"
									alt=""
								/>
								<p>Оплати Долями, без банка, переплат и&nbsp;рисков</p>
								<span>
									Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите
									платёж на&nbsp;4&nbsp;части от&nbsp;<b>1&nbsp;872&nbsp;₽</b>.
									<br /> Всего на&nbsp;6&nbsp;недель. Только для граждан РФ
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Start;

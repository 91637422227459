import _get from "lodash/get";
import _merge from "lodash/merge";
import _uniqueId from "lodash/uniqueId";

import { n8nPath, n8n2Path, WEB_API_PATH, REQUEST_SIGNATURE_HEADER_NAME, GLOBAL_CONFIG_KEY, DEFAULT_COLOR_MODE } from "../constants/common";
import { JsonRpcErrorCode, unwrapJsonRpcError, wrapJsonRpcError } from "../errors";
import { getJWT, setJWT } from "./auth";
import { sha256 } from "./crypto";

function withToken(params) {
  const result = { ...params };
  const jwt = getJWT();
  if (jwt) {
    result.token = jwt;
  }
  return result;
}

async function makeSignature(body, secret) {
  return await sha256(`${body}${secret}`);
}

function sortObjectKeys(obj) {
  return Object.fromEntries(Object.entries(obj).sort());
}

export async function fetchWithN8n(method, params = {}, options = {}, n8nVersion = 1) {
  try {
    const id = _uniqueId(Date.now());

    const body = JSON.stringify(
      sortObjectKeys({
        id,
        jsonrpc: "2.0",
        method,
        params: sortObjectKeys(withToken(params)),
      })
    );

    const signatureWebCheckEnv = process.env.NEXT_PUBLIC_SIGNATURE_WEB_CHECK || process.env.REACT_APP_SIGNATURE_WEB_CHECK;
    const shouldMakeSignature =
      (signatureWebCheckEnv || "") !== "" || _get(window, `["${GLOBAL_CONFIG_KEY}"]["request_signature_required"]`);

    const signature = shouldMakeSignature
      ? await makeSignature(body, _get(window, `["${GLOBAL_CONFIG_KEY}"][${REQUEST_SIGNATURE_HEADER_NAME}]`))
      : "DEV MODE";

    const headers = {
      "Content-Type": _get(options, "headers['Content-Type']") || "application/json",
      [REQUEST_SIGNATURE_HEADER_NAME]: signature,
    };

    const apiPath = n8nVersion === 2 ? n8n2Path : n8nPath;

    const resp = await fetch(
      `${apiPath}/?m=${method}`,
      _merge(options, {
        method: "POST",
        body,
        headers,
      })
    );

    if (!resp.ok) {
      throw new Error(resp.status);
    }

    const result = await resp.json();

    if (result.error) {
      if (result.error.code === JsonRpcErrorCode.Redirect) {
        window.location.replace(result.error.data.redirectURL);
      }

      throw wrapJsonRpcError(result.error);
    }

    return result.result;
  } catch (e) {
    let error = e;
    const jsonRpcError = unwrapJsonRpcError(e);
    if (jsonRpcError) {
      error = jsonRpcError;
    }

    if (error.code === JsonRpcErrorCode.Unauthorized) {
      setJWT(null);
      throw error;
    }

    throw error;
  }
}

export async function fetchWithN8n2(method, params = {}, options = {}) {
  return fetchWithN8n(method, params, options, 2);
}

const memoized = {};

export async function fetchWithMemoById(method, id, n8nVersion = 2) {
  const key = `${method}_${id}`;

  if (key in memoized) {
    return Promise.resolve(memoized[key]);
  }

  return fetchWithN8n(method, { id }, {}, n8nVersion).then(resp => {
    memoized[key] = resp;
    return resp;
  });
}

export function callPgrstRpc(path, profile, method, params = {}, options = {}) {
  const jwt = getJWT();
  const defaultTheme = _get(options, "theme");
  const theme = defaultTheme || window.localStorage.getItem("chakra-ui-color-mode") || DEFAULT_COLOR_MODE;

  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Content-Profile": profile,
    [REQUEST_SIGNATURE_HEADER_NAME]: _get(window, `["${GLOBAL_CONFIG_KEY}"][${REQUEST_SIGNATURE_HEADER_NAME}]`),
    "X-Window-Inner-Width": window.innerWidth,
    "X-Window-Inner-Height": window.innerHeight,
    "X-Theme": theme,
  };

  if (jwt) {
    headers["Authorization"] = `Bearer ${jwt}`;
  }

  return fetch(
    `${path}/${method}`,
    _merge(options, {
      method: "POST",
      body: JSON.stringify(params),
      headers,
    })
  )
    .then(res => {
      if (res.status === 204) {
        return Promise.resolve(null);
      }

      return res.json().then(pgrst => {
        if (!res.ok) {
          if (res.status === JsonRpcErrorCode.Unauthorized) {
            pgrst.code = JsonRpcErrorCode.Unauthorized;
          }

          throw wrapJsonRpcError(pgrst);
        }

        return pgrst;
      });
    })
    .catch(e => {
      let error = e;
      const jsonRpcError = unwrapJsonRpcError(e);

      if (jsonRpcError) {
        error = jsonRpcError;
      }

      if (error.code === JsonRpcErrorCode.Unauthorized) {
        setJWT(null);
      }

      if (error.code === JsonRpcErrorCode.Redirect) {
        window.location.href = error.data.redirectURL;
      }

      throw error;
    });
}

export function callWebRpc(method, params = {}, options = {}) {
  const jwt = getJWT();
  return callPgrstRpc(WEB_API_PATH, jwt ? "web" : "public", method, params, options);
}

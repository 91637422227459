import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Author from './components/Author';
import Programs from './components/Programs';
import Start from './components/Start';
import Faq from './components/Faq';
import Second from './components/Second';
import Second2 from './components/Second2';
import Block1 from './components/Block1';
import Students from './components/Students';
import Gifts from './components/Gifts';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2025_02_SpringSale = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])
    
    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere2' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    const now = new Date();
    if(now.getDate() <= 3) {
      nextMidnight = new Date(Date.UTC(2025,2,4,0,0,0));
    } else {
      nextMidnight = new Date(Date.UTC(2025,2,4,11,0,0));
    }
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Мега-распродажа – максимальная выгода на набор фитнес-программ Кати Усмановой и чат с куратором'/>
        <DocumentDescription value='Максимальная выгода 86% на набор из 6 фитнес-программ от Кати Усмановой. Кураторская поддержка и полезные гайды в подарок' />
        <div className='spring_sale'>
              <Promo scroll={scroll} scroll2={scroll2} date={date} renderer={renderer} />
              <Second />
              <Programs scroll={scroll} date={date} renderer={renderer}/>
              <Block1 />
              <Students scroll={scroll} date={date} renderer={renderer}/>
              <Gifts scroll={scroll} date={date} renderer={renderer}/>
              <Author />
              <Second2 />
              <Start date={date} renderer={renderer}/>
              <Faq scroll={scroll} date={date} renderer={renderer}/>
              <Footer version={7} isColor={'#FFE0E9'} />
        </div>
      </>
    );
};